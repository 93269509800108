$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'event' && a === 'show') {

            let order_clipping_card_validation_ajax_lock = false;

            $(document).on("click", '#clipping-card-code-button', function () {
                validateClippingCard();
            });

            function validateClippingCard() {
                let eventId = $("body").attr("event_id");
                let clippingCardCode = $("#clipping_card_code").val();

                if (!order_clipping_card_validation_ajax_lock) {
                    order_clipping_card_validation_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/order/validate_clipping_card",
                        data: {
                            event_id: eventId,
                            clipping_card_code: clippingCardCode
                        }
                    })
                        .done(function (data) {

                            if (data.clipping_card_discount !== undefined && data.clipping_card_discount !== null) {

                                let orderSummaryTableBody = document.getElementById('table-order-summary');
                                let rows = orderSummaryTableBody.rows

                                let colsSum = rows[rows.length - 2].cells;
                                colsSum[colsSum.length - 1].innerHTML = "<strong>kr 0,00</strong>";

                                let colsVat = rows[rows.length - 1].cells;
                                colsVat[colsVat.length - 1].innerHTML = "Herav mva kr 0,00";

                                $('#clipping-card-td').remove();
                                rows[rows.length - 2].insertAdjacentHTML('beforebegin', "<tr id='clipping-card-td'><td colspan='4' >Rabatt (klippekort: <span id='clipping_card_code_validated'>" + clippingCardCode + "</span>)</td><td class='text-right'>- kr " + data.clipping_card_discount + "</td></tr>");

                                $('.alert-danger').remove();

                            }

                            if (data.success_message !== undefined && data.success_message !== null) {
                                prependAlert('#clipping-card-code-form', data.success_message, 'success', null, '#order_register_form');
                            }

                            order_clipping_card_validation_ajax_lock = false;
                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#clipping-card-code-form', data.responseJSON.error_message, 'danger', null, '#order_register_form');
                            }

                            order_clipping_card_validation_ajax_lock = false;

                        }).always(function () {
                        order_clipping_card_validation_ajax_lock = false;
                    });

                }
            }

            $(document).on("click", "#clipping-card-code-form-button", function () {
                if ($('#clipping-card-code-form').hasClass('d-none')) {
                    $('#clipping-card-code-form').removeClass('d-none');
                } else {
                    $("#clipping_card_code").val(null);
                    $('.alert-danger').remove();
                    $('#clipping-card-code-form').addClass('d-none');
                }
                return false;
            });

        }

    });