$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");

        if (p == 'organizer_event_subevent' && a == 'list') {

            $(document).on("click", "#new-event-ticket-category-deviation-tab", function () {
                $('#new-event-ticket-category-deviation').tab('show');
            });

            let getCategoryDeviationListLock = false;
            $(document).on("click", "#event-ticket-category-deviation-list-tab", function () {
                if (!getCategoryDeviationListLock) {
                    const deviationTable = $('#event-ticket-category-deviation-list-table');
                    if (deviationTable.length > 0) {
                        $('tbody', deviationTable).html('');
                    }
                    $.ajax({
                        type: 'GET',
                        url: "/ajax/organizer_event/get_category_deviation_list",
                        data: {
                            event_id: $("body").attr("event_id")
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                const deviationTable = $('#event-ticket-category-deviation-list-table');
                                if (deviationTable.length > 0) {
                                    $('tbody', deviationTable).html(data.html);
                                }
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON !== undefined && data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            getCategoryDeviationListLock = false;
                        })
                        .always(function () {
                            getCategoryDeviationListLock = false;
                        });
                }

                $('#event-ticket-category-deviation-list').tab('show');
            });

            let getCategoryDeviationFormLock = false;
            $(document).on('submit', '#event-ticket-category-deviation-filter-form', function () {
                if (!getCategoryDeviationFormLock) {
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_event/get_category_deviation_form",
                        data: {
                            event_id: $("body").attr("event_id"),
                            date_from: $('#event-ticket-category-deviation-filter-form #category-deviation-from-date').val(),
                            date_to: $('#event-ticket-category-deviation-filter-form #category-deviation-to-date').val(),
                            ticket_category: $('#event-ticket-category-deviation-filter-form #category-deviation-ticket-category option:selected').val()
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                $('#event-ticket-category-deviation-filter-form-wrapper').html(data.html);
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON !== undefined && data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                $('#event-ticket-category-deviation-filter-form-wrapper').html('');
                                appendAlert('#event-ticket-category-deviation-filter-form-wrapper', data.responseJSON.error_message, 'danger', null, '#new-event-ticket-category-deviation');
                            }

                            getCategoryDeviationFormLock = false;
                        })
                        .always(function () {
                            getCategoryDeviationFormLock = false;
                        });
                }
                return false;
            });

            $(document).on('submit', '#event_subevent_deviation_register_form', function () {
                return false;
            });

            $(document).on('click', '#event_subevent_deviation_register_form .deviation-all-categories-and-timeslots-on, #event_subevent_deviation_register_form .deviation-all-categories-and-timeslots-off', function () {
                let clickedBtn = $(this);
                let closestFormRow = clickedBtn.closest('.form-row');
                let eventSubeventId = $('.event_subevent_deviation_subevent_day', closestFormRow).data('event-subevent-id');
                if (isNaN(eventSubeventId)) {
                    alert('Error');
                    return false;
                }

                let action = clickedBtn.hasClass('deviation-all-categories-and-timeslots-on') ? 'open_all_timeslots' : 'close_all_timeslots';

                let data = {
                    event_id: $("body").attr("event_id"),
                    action: action,
                    event_subevent_id: eventSubeventId
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/save_all_category_deviation_form_element",
                    data: data
                })
                    .done(function (data) {
                        if (data.status !== undefined && data.status == 'OK') {
                            let checkedOrNot = (action === 'open_all_timeslots');
                            $('input.category-subevent-timeslot-deviation-for-all-categories', closestFormRow).prop('checked', checkedOrNot)
                            popupAlert();
                        } else {
                            alert('Error');
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON !== undefined && data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            alert(data.responseJSON.error_message);
                        }
                    });
            });

            $(document).on('change', '#event_subevent_deviation_register_form .category-subevent-timeslot-deviation-for-all-categories, #event_subevent_deviation_register_form .category-subevent-deviation-for-all-categories', function () {
                let deviationCheckbox = $(this);
                let eventSubeventTimeslotId = null;
                let isTimeslot = false;
                if ($(this).hasClass('category-subevent-timeslot-deviation-for-all-categories')) {
                    isTimeslot = true;
                    eventSubeventTimeslotId = $(this).data('event_subevent_timeslot-id');
                }
                let eventSubeventId = $('.event_subevent_deviation_subevent_day', $(this).closest('.form-row')).data('event-subevent-id');
                if (isNaN(eventSubeventId) || (isTimeslot && isNaN(eventSubeventTimeslotId))) {
                    alert('Error');
                    return false;
                }

                let action = deviationCheckbox.is(':checked') ? 'open_all' : 'close_all';

                let data = {
                    event_id: $("body").attr("event_id"),
                    action: action,
                    event_subevent_id: eventSubeventId
                }

                if (eventSubeventTimeslotId !== null) {
                    data.event_subevent_timeslot_id = eventSubeventTimeslotId;
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/save_all_category_deviation_form_element",
                    data: data
                })
                    .done(function (data) {
                        if (data.status !== undefined && data.status == 'OK') {
                            popupAlert();
                        } else {
                            alert('Error');
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON !== undefined && data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            alert(data.responseJSON.error_message);
                        }
                    });
            });

            $(document).on('change', '#event_subevent_deviation_register_form .category-subevent-timeslot-deviation-max-limit, #event_subevent_deviation_register_form .category-subevent-deviation-max-limit', function () {
                let deviationInputField = $(this);
                let categoryDeviationTicketCategorySelect = $('#event-ticket-category-deviation-filter-form #category-deviation-ticket-category option:selected');
                let eventTicketCategoryMaxTickets = categoryDeviationTicketCategorySelect.data('max-tickets');
                let eventTicketCategoryId = categoryDeviationTicketCategorySelect.val();
                let newMaxTickets = $(this).val();
                let eventSubeventTimeslotId = null;
                let isTimeslot = false;
                if ($(this).hasClass('category-subevent-timeslot-deviation-max-limit')) {
                    isTimeslot = true;
                    eventSubeventTimeslotId = $(this).data('event_subevent_timeslot-id');
                }
                let deviationId = null;
                let eventSubeventId = $('.event_subevent_deviation_subevent_day', $(this).closest('.form-row')).data('event-subevent-id');
                if (!isNaN($(this).data('event-ticket-category-subevent-deviation-id'))) {
                    deviationId = $(this).data('event-ticket-category-subevent-deviation-id');
                }
                if (isNaN(newMaxTickets)) {
                    return false;
                }
                if (isNaN(eventTicketCategoryMaxTickets) || isNaN(eventTicketCategoryId) || isNaN(eventSubeventId) || (isTimeslot && isNaN(eventSubeventTimeslotId))) {
                    alert('Error');
                    return false;
                }
                if (deviationId === null && newMaxTickets == eventTicketCategoryMaxTickets) {
                    $(this).removeClass('category-deviation');
                    popupAlert();
                    return false;
                }

                let action = 'new';
                if (deviationId !== null) {
                    if (newMaxTickets == eventTicketCategoryMaxTickets) {
                        action = 'delete';
                    } else {
                        action = 'update';
                    }
                }

                let data = {
                    event_id: $("body").attr("event_id"),
                    ticket_category: eventTicketCategoryId,
                    action: action,
                    max_tickets: newMaxTickets,
                    event_subevent_id: eventSubeventId
                }

                if (eventSubeventTimeslotId !== null) {
                    data.event_subevent_timeslot_id = eventSubeventTimeslotId;
                }
                if (deviationId !== null) {
                    data.event_ticket_category_subevent_deviation_id = deviationId;
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/save_category_deviation_form_element",
                    data: data
                })
                    .done(function (data) {
                        if (data.event_ticket_category_subevent_deviation_id !== undefined && data.event_ticket_category_subevent_deviation_id !== null) {
                            deviationInputField.data('event-ticket-category-subevent-deviation-id', data.event_ticket_category_subevent_deviation_id);
                        }
                        if (action == 'new' || action == 'update') {
                            if (!deviationInputField.hasClass('category-deviation')) {
                                deviationInputField.addClass('category-deviation');
                            }
                        } else if (action == 'delete') {
                            deviationInputField.removeClass('category-deviation');
                            deviationInputField.removeData('event-ticket-category-subevent-deviation-id');
                        }
                        popupAlert();
                    })
                    .fail(function (data) {
                        if (data.responseJSON !== undefined && data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            alert(data.responseJSON.error_message);
                        }
                    });
            });

        }

    }
);