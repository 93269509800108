$(document).ready(
    function () {
        let p = $("body").attr("p");

        if (p === 'organizer_event_subevent_sales_timeslots') {
            let eventId = $("body").attr("event_id");
            let eventSubeventId = $("body").attr("event_subevent_id");
            let orderOrigin = -1;
            let numberOfSoldTicketsId = $('#key-figures-number-of-sold-tickets');
            let eventRevenueId = $('#key-figures-event-revenue');
            let eventFeeId = $('#key-figures-event-fee');

            $('.event-statistic').change(function () {
                orderOrigin = getOrderOrigin();
                params.order_origin = orderOrigin;
                $("#event_statistic_form").submit();
            });

            let params = {
                event_id: eventId,
                event_subevent_id: eventSubeventId,
                order_origin: orderOrigin
            };
            ajaxSubmitParamsAndGetEventTimeslotSalesInformation(params);


            $(document).on("submit", "#event_statistic_form", function (e) {
                e.preventDefault();
                ajaxSubmitParamsAndGetEventTimeslotSalesInformation(params);
            });


            function ajaxSubmitParamsAndGetEventTimeslotSalesInformation(params) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_subevent_sales_timeslots/sales",
                    data: params
                })
                    .done(function (data) {
                        let numberOfSoldTickets = data.number_of_sold_tickets;
                        let eventRevenue = data.event_revenue;
                        let eventFee = data.event_fee;
                        numberOfSoldTicketsId.text(numberOfSoldTickets);
                        eventRevenueId.text("kr " + parseFloat(eventRevenue).format(2, 3, ' ', ','));
                        eventFeeId.text("(gebyr kr " + parseFloat(eventFee).format(2, 3, ' ', ',') + ")");

                        barChartSoldTickets(data.date, data.ticket, 'subevent-timeslot-graph');
                        barChartSoldTickets(data.date, data.ticket_category, 'subevent-timeslot-category-graph');
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            function barChartSoldTickets(x, y, id) {

                let chart = {
                    chart: {type: 'column', styledMode: true},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: x,
                        title: {text: $("#" + id).attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#" + id).attr("data-y-axis-text")}
                    },
                    credits: {enabled: false}
                }

                if (id === 'subevent-timeslot-graph' || y.length === 0) {
                    chart.series = [{
                        showInLegend: false,
                        data: y,
                        name: $("#" + id).attr("data-y-axis-text")
                    }];
                } else {
                    chart.series = y
                }

                Highcharts.chart(id, chart);
            }

            function getOrderOrigin() {
                let orderOrigin = $("#order_origin");
                if (orderOrigin.length > 0) {
                    return orderOrigin.children("option:selected").val();
                }
                return -1;
            }

        }

    });
