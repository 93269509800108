$(document).ready(
    function () {

        let order_ajax_lock = false;

        $(document).on("submit", "#order_register_form", function (e) {

            e.preventDefault();

            if (!order_ajax_lock) {

                $('#order_register_form #order-register-button').prop('disabled', true);
                order_ajax_lock = true;

                let formData = new FormData(document.getElementById('order_register_form'));
                if ($('#clipping_card_code_validated').length !== 0 && $('#clipping_card_code_validated').text() != '') {
                    formData.append('clipping_card_code', $('#clipping_card_code_validated').text());
                } else {
                    if (formData.has('clipping_card_code')) {
                        formData.delete('clipping_card_code');
                    }
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/new",
                    data: formData,
                    async: true,
                    cache: false,
                    processData: false,
                    contentType: false
                })

                    .done(function (data) {

                        let enableSubmitButton = true;
                        if (data.payment_redirect_url !== undefined && data.payment_redirect_url !== null) {
                            if (data.redirect_to_pos !== undefined) {
                                $('#order_register_form').hide();
                            }
                            document.location.href = data.payment_redirect_url;
                            enableSubmitButton = false;
                        }

                        if (data.redirect_url !== undefined && data.redirect_url !== null) {
                            cancelTicketReservation(data.redirect_url)
                        }

                        if (data.confirmation_code !== undefined && data.confirmation_code !== null) {
                            document.location.href = "/order/confirmation/" + data.confirmation_code;
                            enableSubmitButton = false;
                        }

                        if (enableSubmitButton) {
                            $('#order_register_form #order-register-button').prop('disabled', false);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON !== undefined && data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }

                        order_ajax_lock = false;
                        $('#order-register-button').prop('disabled', false);

                    })
                    .always(function () {
                        order_ajax_lock = false;
                    });

            }

        });


        function cancelTicketReservation(redirect_url) {
            $.ajax({
                type: 'GET',
                url: "/ajax/order/cancel_reservation"
            })
                .done(function (data) {
                    document.location.href = redirect_url;
                })
        }

    }
);
