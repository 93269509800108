$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_fees' || p === 'admin_fees_pos' || p === 'seller_fees' || p === 'seller_fees_pos' || p === 'dealer_fees' || p === 'dealer_fees_pos') {

            $(document).on("focusout", ".fee-level", function () {
                $('.alert').remove();
                let feeValue = parseFloat($(this).val());
                let minimumFee = parseFloat($(this).attr('data-minimum-fee'));
                if (feeValue < minimumFee) {
                    let closestCardBox = $(this).closest('.card-box');
                    let errorMessage = $('.organizer-form').attr('data-minimum-fee-error-message');
                    errorMessage = errorMessage + minimumFee;
                    appendAlert(closestCardBox, errorMessage, 'danger', null, 'form .organizer-form');
                }
            });

            $(document).on("click", ".organizer-fee-save-button", function (e) {

                if ($(this).closest("form.organizer-form").hasClass('has-changed')) {
                    $(this).closest("form.organizer-form").removeClass('has-changed');
                }

                $('.alert').remove();

                e.preventDefault();
                let organizerId = $(this).data('organizer-id');
                let feeLevel1 = $('#fee-level-1-organizer-id-' + organizerId).val();
                let feeLevel2 = $('#fee-level-2-organizer-id-' + organizerId).val();
                let feeLevel3 = $('#fee-level-3-organizer-id-' + organizerId).val();
                let feeLevel4 = $('#fee-level-4-organizer-id-' + organizerId).val();
                let feeLevel5 = $('#fee-level-5-organizer-id-' + organizerId).val();
                let feeLevel6 = $('#fee-level-6-organizer-id-' + organizerId).val();
                let feeLevel7 = $('#fee-level-7-organizer-id-' + organizerId).val();
                let feeLevel8 = $('#fee-level-8-organizer-id-' + organizerId).val();
                let feeLevel9 = $('#fee-level-9-organizer-id-' + organizerId).val();
                let feeLevel10 = $('#fee-level-10-organizer-id-' + organizerId).val();
                let feeLevel11 = $('#fee-level-11-organizer-id-' + organizerId).val();
                let feeLevel12 = $('#fee-level-12-organizer-id-' + organizerId).val();
                let feePercent = $('#fee-percent-organizer-id-' + organizerId).val();
                let feeOrigin = $('#fee-origin-organizer-id-' + organizerId).val();

                let data = {
                    organizer_id: organizerId,
                    fee_level_1: feeLevel1,
                    fee_level_2: feeLevel2,
                    fee_level_3: feeLevel3,
                    fee_level_4: feeLevel4,
                    fee_level_5: feeLevel5,
                    fee_level_6: feeLevel6,
                    fee_level_7: feeLevel7,
                    fee_level_8: feeLevel8,
                    fee_level_9: feeLevel9,
                    fee_level_10: feeLevel10,
                    fee_level_11: feeLevel11,
                    fee_level_12: feeLevel12,
                    fee_percent: feePercent,
                    fee_origin: feeOrigin,
                    fee_percent_only: $('#fee-percent-only-organizer-id-' + organizerId).val()
                };

                let feeTypePercent = $('#fee-type-percent-' + organizerId);
                let feeType = 0;
                if (feeTypePercent.is(':checked')) {
                    feeType = 1;
                }
                data.fee_type = feeType;

                if ($('#fee-pos-level-1-organizer-id-' + organizerId).length > 0) {
                    data.fee_pos_level_1 = $('#fee-pos-level-1-organizer-id-' + organizerId).val();
                    data.fee_pos_level_2 = $('#fee-pos-level-2-organizer-id-' + organizerId).val();
                    data.fee_pos_level_3 = $('#fee-pos-level-3-organizer-id-' + organizerId).val();
                    data.fee_pos_level_4 = $('#fee-pos-level-4-organizer-id-' + organizerId).val();
                    data.fee_pos_level_5 = $('#fee-pos-level-5-organizer-id-' + organizerId).val();
                    data.fee_pos_level_6 = $('#fee-pos-level-6-organizer-id-' + organizerId).val();
                    data.fee_pos_level_7 = $('#fee-pos-level-7-organizer-id-' + organizerId).val();
                    data.fee_pos_level_8 = $('#fee-pos-level-8-organizer-id-' + organizerId).val();
                    data.fee_pos_level_9 = $('#fee-pos-level-9-organizer-id-' + organizerId).val();
                    data.fee_pos_level_10 = $('#fee-pos-level-10-organizer-id-' + organizerId).val();
                    data.fee_pos_level_11 = $('#fee-pos-level-11-organizer-id-' + organizerId).val();
                    data.fee_pos_level_12 = $('#fee-pos-level-12-organizer-id-' + organizerId).val();
                    data.fee_pos_percent = $('#fee-pos-percent-organizer-id-' + organizerId).val();
                    data.fee_pos_percent_only = $('#fee-pos-percent-only-organizer-id-' + organizerId).val();
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_fees/update",
                    data: data
                })
                    .done(function (data) {

                        if (data === true) {
                            popupAlert();
                        }

                    })
                    .fail(function (data) {
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            appendAlert($('.organizer-fee-save-button').parent(), data.responseJSON.error_message, 'danger', null, 'form .organizer-form');
                        }

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

            $(document).on('click', '.organizer-fee-change-type', function () {
                let feeTypePercentCheckbox = $('.fee-type-percent-checkbox', $(this).closest('.organizer-form'));
                if ($(this).hasClass('organizer-fee-type-percent')) {
                    $('#organizer-fee-type-step-container').addClass('d-none');
                    $('#organizer-fee-type-percent-container').removeClass('d-none');
                    feeTypePercentCheckbox.prop('checked', true).change();
                } else {
                    $('#organizer-fee-type-percent-container').addClass('d-none');
                    $('#organizer-fee-type-step-container').removeClass('d-none');
                    feeTypePercentCheckbox.prop('checked', false).change();
                }
            });

            $(document).on('change', '.organizer-form .fee-type-percent-checkbox', function () {
                let organizerForm = $(this).closest('.organizer-form');
                let stepWeb = $('.fee-container-step-web', organizerForm);
                let percentWeb = $('.fee-container-percent-web', organizerForm);
                let stepPos = $('.fee-container-step-pos', organizerForm);
                let percentPos = $('.fee-container-percent-pos', organizerForm);
                let stepChangeContainer = $('#organizer-fee-type-step-container', organizerForm);
                let percentChangeContainer = $('#organizer-fee-type-percent-container', organizerForm);
                if ($(this).is(':checked')) {
                    stepWeb.addClass('d-none');
                    if (stepPos.length > 0) {
                        stepPos.addClass('d-none');
                    }
                    percentWeb.removeClass('d-none');
                    if (percentPos.length > 0) {
                        percentPos.removeClass('d-none');
                    }
                    stepChangeContainer.addClass('d-none');
                    percentChangeContainer.removeClass('d-none');
                } else {
                    stepWeb.removeClass('d-none');
                    if (stepPos.length > 0) {
                        stepPos.removeClass('d-none');
                    }
                    percentWeb.addClass('d-none');
                    if (percentPos.length > 0) {
                        percentPos.addClass('d-none');
                    }
                    stepChangeContainer.removeClass('d-none');
                    percentChangeContainer.addClass('d-none');
                }
            });

        }

    }
);