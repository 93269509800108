$(document).ready(
    function () {

        $(document).on("submit", "#admin_organizer_fee_visit_tracker_search_form", function () {
            if ($("#admin-organizer-fee-search").length > 0) {
                console.log($(this).serialize());

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_fees_visit_tracker/search",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#admin-organizer-fee").remove();
                            $("#admin-organizer-fee-search").after(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });
            }

            return false;

        });

    }
);