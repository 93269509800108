$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event_form' && a == 'edit') {

            $(document).on("change ", '.element_type', function () {
                let elementTypeSelect = $(this);
                let EventFormQuestion = $(this).closest('div.event-form-question');
                let addAnswerOptionIconWrapper = EventFormQuestion.find('div.add-event-form-answer-option-icon-wrapper');
                if (elementTypeSelect.val() == 0 || elementTypeSelect.val() == 4) {
                    EventFormQuestion.find('div.event-form-answer-option').remove();
                    if (!addAnswerOptionIconWrapper.hasClass('d-none')) {
                        addAnswerOptionIconWrapper.addClass('d-none');
                    }
                } else {
                    if (addAnswerOptionIconWrapper.hasClass('d-none')) {
                        addAnswerOptionIconWrapper.removeClass('d-none');
                    }
                }
            });

            if ($("#event-form-question-form").length) {
                let eventFormQuestionForm = document.getElementById("event-form-question-form");
                Sortable.create(eventFormQuestionForm, {
                    handle: ".event-form-question-reorder",
                    animation: 150,
                    sort: true,
                    delay: 0,
                    touchStartThreshold: 0,
                    disabled: false,
                    draggable: ".event-form-question",
                    direction: "horizontal",
                    ghostClass: "sortable-ghost",
                    removeCloneOnHide: true,
                });
            }

            let eventFormAnswerOptionWrapper = $(".event-form-answer-option-wrapper");
            eventFormAnswerOptionWrapper.each(function () {
                let eventFormAnswerOptionWrapper = document.getElementById($(this).attr('id'));
                Sortable.create(eventFormAnswerOptionWrapper, {
                    handle: ".event-form-answer-option-reorder",
                    animation: 150,
                    sort: true,
                    delay: 0,
                    touchStartThreshold: 0,
                    disabled: false,
                    draggable: ".event-form-answer-option",
                    direction: "horizontal",
                    ghostClass: "sortable-ghost",
                    removeCloneOnHide: true,
                });
            });

        }

        if (p == 'organizer_event_form') {
            let perOrderAjaxLock = false;
            if (!perOrderAjaxLock) {
                perOrderAjaxLock = true;
                $(document).on("change", "#per_order", function () {
                    const perOrder = ($(this).is(':checked')) ? 1 : 0;
                    const eventFormId = ($(this).data('event_form_id') !== undefined && !isNaN($(this).data('event_form_id')) && $(this).data('event_form_id') > 0) ? $(this).data('event_form_id') : 0;

                    if (perOrder) {
                        $('.form-check-inline input[value="all"]').prop('checked', true).change();
                        $('.event-form-question-categories').each(function () {
                            $(this).addClass('d-none');
                        });
                    } else {
                        $('.event-form-question-categories').each(function () {
                            $(this).removeClass('d-none');
                        });
                    }

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_event_form/event_form",
                        data: {
                            event_id: $("body").attr("event_id"),
                            per_order: perOrder,
                            event_form_id: eventFormId
                        }
                    })
                        .done(function (data) {
                            perOrderAjaxLock = false;
                            if (data.message !== undefined && data.message !== null) {
                                popupAlert();
                            }
                        })
                        .fail(function (data) {
                            perOrderAjaxLock = false;
                            popupAlert(500, 'danger');
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                        });

                    return false;

                });
            }
        }

    });





