$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_fees' || p === 'admin_fees_pos' || p === 'admin_fees_visit_tracker' || p === 'seller_fees' || p === 'seller_fees_pos' || p === 'seller_fees_visit_tracker' || p === 'dealer_fees' || p === 'dealer_fees_pos' || p === 'dealer_fees_visit_tracker') {

            $(document).on("submit", "#admin_organizer_fee_search_form", function () {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/admin_fees/search",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#admin-organizer-fee").remove();
                            $("#admin-organizer-fee-search").after(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

                return false;

            });

            $(document).on('change', '#admin_organizer_fee_search_form select#organizer_id', function () {
                $('#admin_organizer_fee_search_form').submit();
            });

        }
    }
);