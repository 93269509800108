$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_event_ticket_category' && a == 'new') {

            if ($("#event_ticket_category_id").val() === undefined || $("#event_ticket_category_id").val() == null || $("#event_ticket_category_id").val() === "") {

                let event_ticket_category_new_ajax_lock = false;

                $(document).on("submit", "#event_ticket_category_form", function () {

                    if (!event_ticket_category_new_ajax_lock) {

                        let event_id = $("#event_id").val();

                        event_ticket_category_new_ajax_lock = true;

                        $(':disabled').each(function () {
                            $(this).attr('data-was-disabled', '1');
                            $(this).removeAttr('disabled');
                        });

                        let formData = new FormData(document.getElementById('event_ticket_category_form'));

                        $.ajax({
                            type: 'POST',
                            url: "/ajax/organizer_event_ticket_category/new",
                            data: formData,
                            async: true,
                            cache: false,
                            processData: false,
                            contentType: false
                        })
                            .done(function (data) {

                                if (data.message !== undefined && data.message !== null && data.event_ticket_category_id !== undefined && data.event_ticket_category_id !== null) {

                                    prependAlert('#div_event_ticket_category_button', data.message, 'success', null, '#event_ticket_category_form');
                                    $('.card-box-to-hide').hide();
                                    $('#event_ticket_category_button').hide();

                                    setTimeout(function () {
                                        document.location.href = "/organizer/event/" + event_id + "/ticket_category/";
                                    }, 2000);

                                }

                            })
                            .fail(function (data) {

                                if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                    document.location.href = data.responseJSON.redirect_url;
                                }

                                if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                    prependAlert('#div_event_ticket_category_button', data.responseJSON.error_message, 'danger', null, '#event_ticket_category_form')
                                }

                                event_ticket_category_new_ajax_lock = false;

                            })
                            .always(function () {
                                event_ticket_category_new_ajax_lock = false;

                                $('[data-was-disabled="1"]').each(function () {
                                    $(this).prop('disabled', true);
                                });
                            });

                    }

                    return false;

                });

            }

        }

    }
);