$(document).ready(
    function () {
        let p = $("body").attr("p");
        if (p === 'organizer_recurring_event_entered_customers') {
            let eventStatistic = $('.event-statistic');
            let eventId = $("body").attr("event_id");
            let startDate = $('#start-date').val();
            let endDate = $('#end-date').val();
            let orderOrigin = $("#order_origin").children("option:selected").val();

            let params = {
                event_id: eventId,
                start: startDate,
                end: endDate,
                order_origin: orderOrigin
            };

            eventStatistic.change(function () {
                $("#recurring_event_entered_customers_form").submit();
            });

            $(document).on("submit", "#recurring_event_entered_customers_form", function (e) {
                e.preventDefault();
                ajaxSubmitParamsAndGetNumberOfCustomers($(this).serialize());
            });

            function ajaxSubmitParamsAndGetNumberOfCustomers(params) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_recurring_event_entered_customers/statistic",
                    data: params
                })
                    .done(function (data) {
                        if (data !== undefined && data.count !== undefined) {
                            $('#key-figures-number-of-sold-tickets').text(data.count);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }
            ajaxSubmitParamsAndGetNumberOfCustomers(params);

            $(document).on("click", "#organizer_recurring_event_entered_customers_excel_btn", function (e) {
                const currentUrl = $('.nav-link.active').attr('href');
                document.location.href = currentUrl + '?file=csv&start=' + $('#start-date').val() + '&end=' + $('#end-date').val() + '&entry_point=' + $("#event_entry_point_id").children("option:selected").val();
            });
        }
    }
);
