$(document).ready(function () {
    let p = $("body").attr("p");
    if (p == 'organizer_dashboard') {
        $('#dashboard-filters-toggle-btn').click(function () {
            let dashboardFilters = $('#dashboard-filters');
            if (dashboardFilters.hasClass('d-none')) {
                dashboardFilters.removeClass('d-none')
            } else {
                dashboardFilters.addClass('d-none');
            }
        });

        let startDate = $('#start-date').val();
        let endDate = $('#end-date').val();
        let period = 'day';
        let orderOrigin = $("#order_origin").children("option:selected").val();
        let numberOfSoldTicketsId = $('#key-figures-number-of-sold-tickets');
        let eventRevenueId = $('#key-figures-event-revenue');
        let startDateId = $('#start-date');
        let endDateId = $('#end-date');
        let periodHourId = $('#period-hour');
        let periodDayId = $('#period-day');
        let periodWeekId = $('#period-week');
        let eventStatistic = $('.event-statistic');
        let graphContent = $("input[name='graph_content']");
        let graphContentValue = 'economy';

        if (periodDayId.prop("checked") === false) {
            periodDayId.prop("checked", true);
        }

        let params = {
            start: startDate,
            end: endDate,
            period: period,
            order_origin: orderOrigin,
            graph_content: graphContentValue
        };

        getSalesInformation(params);
        getEventsSalesInformation(params);

        startDateId.change(function () {
            disablePeriodHour();
        });

        endDateId.change(function () {
            disablePeriodHour();
        });

        eventStatistic.change(function () {
            startDate = $('#start-date').val();
            endDate = $('#end-date').val();
            period = $('input[name=period]:checked').val();
            orderOrigin = $("#order_origin").children("option:selected").val();
            graphContentValue = $("input[name='graph_content']:checked").val();
            $("#dashboard-filters-form").submit();
        });

        $('[data-toggle="tooltip"]').tooltip();

        $(document).on("submit", "#dashboard-filters-form", function (e) {
            e.preventDefault();

            if ($('#dashboard-stats-total-tab').hasClass('active')) {
                getSalesInformation($(this).serialize());
            }
            getEventsSalesInformation($(this).serialize());
        });

        function getSalesInformation(params) {
            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_dashboard/sales_total",
                data: params
            })
                .done(function (data) {
                    let numberOfSoldTickets = data.number_of_sold_tickets;
                    let eventRevenue = data.event_revenue;
                    numberOfSoldTicketsId.text(numberOfSoldTickets);
                    eventRevenueId.text("kr " + parseFloat(eventRevenue).format(2, 3, ' ', ','));

                    if (data.y_axis_text !== undefined) {
                        $('#dashboard-total-line-graph').attr('data-y-axis-text', data.y_axis_text);
                        $('#dashboard-total-bar-graph').attr('data-y-axis-text', data.y_axis_text);
                        delete data['y-axis-text'];
                    }
                    if (data.y_axis_accumulated_text !== undefined) {
                        $('#dashboard-total-line-graph').attr('data-y-axis-accumulated-text', data.y_axis_accumulated_text);
                        delete data['y-axis-accumulated-text'];
                    }

                    highchartsLineChart(data, 'dashboard-total-line-graph');
                    highchartsBarChart(data, 'dashboard-total-bar-graph');
                })
                .fail(function (data) {
                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }
                })
        }

        function getEventsSalesInformation(params, drawPies = true) {
            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_dashboard/sales_events",
                data: params
            })
                .done(function (data) {
                    if (data.pie_series_tickets !== undefined) {
                        if (drawPies === true) {
                            highchartsPieChart(data.pie_series_tickets, 'dashboard-pie-tickets');
                        }
                        delete data['pie_series_tickets'];
                    }
                    if (data.pie_series_revenue !== undefined) {
                        if (drawPies === true) {
                            highchartsPieChart(data.pie_series_revenue, 'dashboard-pie-revenue');
                        }
                        delete data['pie_series_revenue'];
                    }

                    if (data.y_axis_text !== undefined) {
                        $('#dashboard-events-line-graph').attr('data-y-axis-text', data.y_axis_text);
                        $('#dashboard-events-bar-graph').attr('data-y-axis-text', data.y_axis_text);
                        delete data['y-axis-text'];
                    }
                    if (data.y_axis_accumulated_text !== undefined) {
                        $('#dashboard-events-line-graph').attr('data-y-axis-accumulated-text', data.y_axis_accumulated_text);
                        delete data['y-axis-accumulated-text'];
                    }

                    if ($('#dashboard-stats-events-tab').hasClass('active')) {
                        let lineChartData = jQuery.extend(true, {}, data);
                        highchartsLineChartAccumulated(lineChartData, 'dashboard-events-line-graph');
                        highchartsBarStackedChart(data, 'dashboard-events-bar-graph');
                        salesSummaryTable(data, graphContentValue);
                    }
                })
                .fail(function (data) {
                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }
                })
        }

        function salesSummaryTable(primalData, currentGraphContentValue) {

            let tableSalesSummary = $("#dashboard-stats-events-table tbody");
            tableSalesSummary.empty();

            let eventTicketCategoryName;
            let eventTicketCategoryTicketSold;
            let eventTicketNonContainerCategoryTicketSold;
            let eventTicketCategoryRevenue;
            let numberOfDiscountedTickets;
            let discountedAmount;
            let numberOfDiscountedTicketsTotal = 0;
            let discountedAmountTotal = 0;
            let eventTicketCategoryTicketSoldTotal = 0;
            let eventTicketCategoryRevenueTotal = 0;
            let containerCategory;

            $.each(primalData.ticket, function (key, item) {

                eventTicketCategoryName = '';
                eventTicketCategoryTicketSold = 0;
                eventTicketNonContainerCategoryTicketSold = 0;
                eventTicketCategoryRevenue = 1;
                numberOfDiscountedTickets = 0;
                discountedAmount = 0;
                containerCategory = (item.container_category !== undefined && item.container_category === true);

                if (containerCategory) {
                    if ($('#category-container-info').length > 0) {
                        $('#category-container-info').removeClass('d-none');
                    }
                }

                let displayDirectlySoldTicketsInfoText = false;

                $.each(this, function (name, value) {
                    if (name === 'name') {
                        eventTicketCategoryName = value;
                        if (containerCategory) {
                            eventTicketCategoryName = eventTicketCategoryName + '*';
                        }
                    }
                    if ((name === 'data' && currentGraphContentValue === 'tickets') || (name === 'tickets' && currentGraphContentValue === 'economy')) {
                        eventTicketCategoryTicketSold = parseInt(value.reduce((a, b) => a + b, 0));
                        if (!containerCategory) {
                            eventTicketCategoryTicketSoldTotal += eventTicketCategoryTicketSold;
                        }
                    }
                    if (name === 'data_not_in_container') {
                        eventTicketNonContainerCategoryTicketSold = parseInt(value.reduce((a, b) => a + b, 0));
                        displayDirectlySoldTicketsInfoText = true;
                    }
                    if ((name === 'revenue' && currentGraphContentValue === 'tickets') || (name === 'data' && currentGraphContentValue === 'economy')) {
                        eventTicketCategoryRevenue = parseFloat(value.reduce((c, d) => parseFloat(c) + parseFloat(d), 0));
                        eventTicketCategoryRevenueTotal += eventTicketCategoryRevenue;
                    }
                    if (name === 'discounted_tickets') {
                        numberOfDiscountedTickets = value;
                        numberOfDiscountedTicketsTotal += numberOfDiscountedTickets;
                    }
                    if (name === 'discounted_amount') {
                        discountedAmount = value;
                        discountedAmountTotal += discountedAmount;
                    }
                    if (name === 'url') {
                        eventTicketCategoryName = '<a href="' + value + '">' + eventTicketCategoryName + '</a>';
                    }
                });
                if (displayDirectlySoldTicketsInfoText && !isNaN(eventTicketNonContainerCategoryTicketSold) && eventTicketNonContainerCategoryTicketSold > 0 && eventTicketNonContainerCategoryTicketSold != eventTicketCategoryTicketSold) {
                    if ($('#category-container-directly-sold-info').length > 0) {
                        $('#category-container-directly-sold-info').removeClass('d-none');
                    }
                }
                salesSummaryTableTr(tableSalesSummary, eventTicketCategoryName, eventTicketCategoryTicketSold, eventTicketCategoryRevenue, numberOfDiscountedTickets, discountedAmount, eventTicketNonContainerCategoryTicketSold);
            });
            salesSummaryTableTotalTr(tableSalesSummary, eventTicketCategoryTicketSoldTotal, eventTicketCategoryRevenueTotal, numberOfDiscountedTicketsTotal, discountedAmountTotal);
        }


        function salesSummaryTableTr(tableSalesSummary, eventTicketCategoryName, eventTicketCategoryTicketSold, eventTicketCategoryRevenue, numberOfDiscountedTickets, discountedAmount, eventTicketNonContainerCategoryTicketSold) {
            let soldContainerTicketsText = '';//(!isNaN(eventTicketNonContainerCategoryTicketSold) && eventTicketNonContainerCategoryTicketSold > 0 && eventTicketNonContainerCategoryTicketSold != eventTicketCategoryTicketSold) ? ' (' + eventTicketNonContainerCategoryTicketSold + ')' : '';
            if (numberOfDiscountedTickets > 0) {
                tableSalesSummary.append($('<tr data-toggle="tooltip" title="Antall rabatterte billetter: ' + numberOfDiscountedTickets + ' stk' + "\n" + 'Sum rabatt eks mva: ' + discountedAmount.format(2, 3, ' ', ',') + ' kr">')
                    .append($('<td>').append(eventTicketCategoryName))
                    .append($('<td>').append(eventTicketCategoryTicketSold + '' + soldContainerTicketsText))
                    .append($('<td>').append('kr ' + eventTicketCategoryRevenue.format(2, 3, ' ', ',')))
                )
            } else {
                tableSalesSummary.append($('<tr>')
                    .append($('<td>').append(eventTicketCategoryName))
                    .append($('<td>').append(eventTicketCategoryTicketSold + '' + soldContainerTicketsText))
                    .append($('<td>').append('kr ' + eventTicketCategoryRevenue.format(2, 3, ' ', ',')))
                )
            }
        }


        function salesSummaryTableTotalTr(tableSalesSummary, eventTicketCategoryTicketSoldTotal, eventTicketCategoryRevenueTotal, numberOfDiscountedTicketsTotal, discountedAmountTotal) {
            numberOfSoldTicketsId.text(eventTicketCategoryTicketSoldTotal);
            eventRevenueId.text("kr " + parseFloat(eventTicketCategoryRevenueTotal).format(2, 3, ' ', ','));

            if (numberOfDiscountedTicketsTotal > 0) {
                tableSalesSummary.append($('<tr data-toggle="tooltip" title="Antall rabatterte billetter: ' + numberOfDiscountedTicketsTotal + ' stk' + "\n" + 'Sum rabatt eks mva: ' + discountedAmountTotal.format(2, 3, ' ', ',') + ' kr">')
                    .append($('<td>').append('<strong>Total</strong>'))
                    .append($('<td>').append('<strong>' + eventTicketCategoryTicketSoldTotal + '</strong>'))
                    .append($('<td>').append('<strong>kr ' + eventTicketCategoryRevenueTotal.format(2, 3, ' ', ',') + '</strong>'))
                )
            } else {
                tableSalesSummary.append($('<tr>')
                    .append($('<td>').append('<strong>Total</strong>'))
                    .append($('<td>').append('<strong>' + eventTicketCategoryTicketSoldTotal + '</strong>'))
                    .append($('<td>').append('<strong>kr ' + eventTicketCategoryRevenueTotal.format(2, 3, ' ', ',') + '</strong>'))
                )
            }
        }

        function disablePeriodHour() {
            /*if (startDateId.val().length !== 0 &&
                endDateId.val().length !== 0 &&
                (Date.parse(endDateId.val()) - Date.parse(startDateId.val())) > ((31536000 * 1000)*3)) {
                $('#start-date').val(startDate);
                $('#end-date').val(endDate);
            }*/

            if (startDateId.val().length !== 0 &&
                endDateId.val().length !== 0 &&
                (Date.parse(endDateId.val()) - Date.parse(startDateId.val())) < (172800 * 1000)) {
                $(periodHourId).prop("disabled", false);
            } else {
                if (periodHourId.prop("checked") === true) {
                    periodDayId.prop("checked", true);
                }
                $(periodHourId).prop("disabled", true);
            }

            if (startDateId.val().length !== 0 &&
                endDateId.val().length !== 0 &&
                (Date.parse(endDateId.val()) - Date.parse(startDateId.val())) < (7862400 * 1000)) {
                $(periodDayId).prop("disabled", false);
            } else {
                if (periodDayId.prop("checked") === true) {
                    periodWeekId.prop("checked", true);
                }
                $(periodDayId).prop("disabled", true);
            }
        }

        $('#dashboard-stats-tabs a').on('click', function () {
            $(this).tab('show');
            if (this.id == 'dashboard-stats-total-tab') {
                getSalesInformation($('#dashboard-filters-form').serialize());
            } else if (this.id == 'dashboard-stats-events-tab') {
                getEventsSalesInformation($('#dashboard-filters-form').serialize(), false);
            }
            return false;
        });
    }
});





