$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_event_customers' || p == 'organizer_event_subevent_customers' || p == 'organizer_event_period_tickets') {
            $(document).on("submit", "#ticket_search_form", function () {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_customers/search",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#ticket-list").remove();
                            $(".pagination").remove();
                            $("#ticket-list-search").after(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    });

                return false;

            });

            if (p == 'organizer_event_period_tickets') {
                $('#customer_period_tickets_download_button').click(function (e) {
                    e.preventDefault();
                    let url = $(this).prop('href');
                    let href = new URL(url);
                    href.searchParams.set('start', $('#ticket_search_form #start-date').val());
                    href.searchParams.set('end', $('#ticket_search_form #end-date').val());
                    location.href = href.href;
                });
            }

        }
    }
);