$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_organizers' || p === 'dealer_organizers' || p === 'seller_organizers' || p === 'organizer_settings_config') {
            $(document).on("click", ".config-option-event-form-switch", function (e) {
                let organizerId = $(this).attr('data-organizer-id');
                if ($(this).is(":checked") && $('#config-option-event-form-answer-wrapper-' + organizerId).hasClass('d-none')) {
                    $('#config-option-event-form-answer-wrapper-' + organizerId).removeClass('d-none');
                }
                if (!$(this).is(":checked")) {
                    $('#event_form_answer' + organizerId).prop("checked", false);
                    if (!$('#config-option-event-form-answer-wrapper-' + organizerId).hasClass('d-none')) {
                        $('#config-option-event-form-answer-wrapper-' + organizerId).addClass('d-none');
                    }
                }
            });

            $(document).on("click", ".config-option-subevent-week-ahead-switch", function (e) {
                let organizerId = $(this).attr('data-organizer-id');
                if ($(this).is(":checked")) {
                    $('#subevent_to_date-' + organizerId).val(null);
                    $('#subevent_to_date-' + organizerId).prop('required', false);
                    if (!$('#config-option-subevent-to-date-wrapper-' + organizerId).hasClass('d-none')) {
                        $('#config-option-subevent-to-date-wrapper-' + organizerId).addClass('d-none');
                    }
                    $('#subevent_to_date_' + organizerId).prop("checked", false);
                }
            });

            $(document).on("click", ".config-option-subevent-to-date-switch", function (e) {
                let organizerId = $(this).attr('data-organizer-id');
                if ($(this).is(":checked")) {
                    $('#subevent_week_ahead_' + organizerId).prop("checked", false);
                    if ($('#config-option-subevent-to-date-wrapper-' + organizerId).hasClass('d-none')) {
                        $('#config-option-subevent-to-date-wrapper-' + organizerId).removeClass('d-none');
                    }
                    $('#subevent_to_date-' + organizerId).prop('required', true);

                    let $configOptionSubeventToDate = $(".datepicker-config-option-subevent-to-date-" + organizerId).pickadate({
                        format: 'yyyy-mm-dd',
                        formatSubmit: 'yyyy-mm-dd',
                        editable: true
                    });
                    $(document).on("click", ".datepicker-config-option-subevent-to-date-" + organizerId, function () {
                        let pickerConfigOptionSubeventToDate = $configOptionSubeventToDate.pickadate('picker');
                        pickerConfigOptionSubeventToDate.open();
                    });


                }
                if (!$(this).is(":checked")) {
                    $('#subevent_to_date-' + organizerId).val(null);
                    $('#subevent_to_date-' + organizerId).prop('required', false);
                    if (!$('#config-option-subevent-to-date-wrapper-' + organizerId).hasClass('d-none')) {
                        $('#config-option-subevent-to-date-wrapper-' + organizerId).addClass('d-none');
                    }
                }
            });

        }
    }
);