$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'organizer_event_customers' || p == 'organizer_event_subevent_customers' || p == 'organizer_event_period_tickets') {

            $(document).on("click", ".order-customer-email", function () {
                let ticketId = $(this).attr('data-ticket-id');
                let orderId = $(this).data('order-id');
                if ($('#order-customer-new-email-div-' + orderId + '-' + ticketId).hasClass('d-none')) {
                    $('#order-customer-new-email-div-' + orderId + '-' + ticketId).removeClass('d-none');

                }
                if (!($('#order-customer-current-email-div-' + orderId + '-' + ticketId).hasClass('d-none'))) {
                    $('#order-customer-current-email-div-' + orderId + '-' + ticketId).addClass('d-none');
                }
            });

            $(document).on("click", ".order-customer-send-ticket", function (e) {
                e.preventDefault();
                let orderCustomerId = $(this).attr('data-order-customer-id');
                let orderReference = $(this).attr('data-order-reference');
                let orderId = $(this).closest('.modal').data('order-id');
                let ticketId = $(this).attr('data-ticket-id');
                let email = $('#customer-email-input-' + orderId + '-' + ticketId).val();
                sendTicket(orderCustomerId, orderReference, ticketId, email, orderId);
            });

            $(document).on("click", ".order-customer-save-email", function (e) {
                e.preventDefault();
                let orderCustomerId = $(this).attr('data-order-customer-id');
                let orderReference = $(this).attr('data-order-reference');
                let ticketId = $(this).attr('data-ticket-id');
                let orderId = $(this).closest('.modal').data('order-id');
                let email = $('#customer-email-input-' + orderId + '-' + ticketId).val();
                saveNewEmail(orderCustomerId, orderReference, ticketId, email, orderId);
            });

            $(document).on("click", ".cancel-new-email-button", function () {
                $('.order-customer-new-email-div').each(function () {
                    if (!($(this).hasClass('d-none'))) {
                        $(this).addClass('d-none');
                    }
                });
                $('.order-customer-current-email-div').each(function () {
                    if ($(this).hasClass('d-none')) {
                        $(this).removeClass('d-none');
                    }
                });
            });

            function saveNewEmail(orderCustomerId, orderReference, ticketId, email, orderId) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_customers/save_email",
                    data: {
                        order_customer_id: orderCustomerId,
                        email: email
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#modal-footer-' + orderId + '-' + ticketId, data.message, 'success', null, '#modal-ticket-' + orderId + '-' + ticketId);
                        }

                        $('.order-customer-current-email-' + orderCustomerId).each(function () {
                            $(this).html(email);
                        });

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })
            }

            function sendTicket(orderCustomerId, orderReference, ticketId, email, orderId) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_customers/send_ticket",
                    data: {
                        ticket_id: ticketId,
                        email: email
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#modal-footer-' + orderId + '-' + ticketId, data.message, 'success', null, '#modal-ticket-' + orderId + '-' + ticketId);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }

        }
    }
);