$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event' && a == 'update') {

            $(document).on("change", '#clipping_card_event', function () {
                getEventTicketCategoryRegistrationForm();
            });
            getEventTicketCategoryRegistrationForm();

            function getEventTicketCategoryRegistrationForm() {
                let eventId = $("#clipping_card_event").val();
                let clippingCartEventId = $("body").attr("event_id");
                if (eventId == 0 || eventId === undefined) {
                    return false;
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/get_event_ticket_category",
                    data: {
                        event_id: eventId,
                        clipping_cart_event_id: clippingCartEventId
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $('#event-ticket-category-wrapper').remove();
                            $('#event-ticket-category').append(data.html);
                            $('#event-ticket-category').removeClass('d-none');
                            $('.clipping-card-event-ticket-category').removeClass('d-none');
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON !== undefined && data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

            }
        }


    }
);