$(document).ready(
    function () {

        function addOrRemove(event_ticket_category_id, amount, add_or_remove, max = null) {

            if (add_or_remove == "add") {
                if (amount !== undefined && amount !== null) {
                    if (amount < max) {
                        amount = amount + 1;
                    }
                } else {
                    amount = 1;
                }
            }

            if (add_or_remove == "remove") {
                if (amount > 0) {
                    amount = amount - 1;
                }
            }

            return amount;
        }

        function isContainerMaxReached(id, max) {
            let total = 0;
            $(':input[data-container-id="' + id + '"]').each(function () {
                let val = $(this).val();
                if (!isNaN(val)) {
                    total += val * 1;
                }
            });
            return (total + 1 > max);
        }

        $(document).on("click", ".add-ticket", function (e) {

            e.preventDefault();

            let event_ticket_category_id = $(this).attr("data-event-ticket-category-id");

            let input;

            if ($(this).data('container-type') !== undefined && $(this).data('container-type') == 2) {
                input = $("#event-ticket-sub-products-container-" + event_ticket_category_id);
            } else if ($(this).data('container-event-ticket-category-id') !== undefined) {
                input = $("#event-ticket-container-" + $(this).data('container-event-ticket-category-id') + "-category-" + event_ticket_category_id);

                if (isContainerMaxReached(input.data("container-id"), input.data("container-max"))) {
                    return;
                }
            } else {
                input = $("#event-ticket-category-" + event_ticket_category_id);
            }

            let amount = parseInt(input.val());
            let max = parseInt(input.attr("max"));

            let new_amount = addOrRemove(event_ticket_category_id, amount, "add", max);
            input.val(new_amount);

            checkMaxForCategory(null, input);

        });

        $(document).on("click", ".remove-ticket", function (e) {

            e.preventDefault()

            let event_ticket_category_id = $(this).attr("data-event-ticket-category-id");

            let input;

            if ($(this).data('container-type') !== undefined && $(this).data('container-type') == 2) {
                input = $("#event-ticket-sub-products-container-" + event_ticket_category_id);
            } else if ($(this).data('container-event-ticket-category-id') !== undefined) {
                input = $("#event-ticket-container-" + $(this).data('container-event-ticket-category-id') + "-category-" + event_ticket_category_id);
            } else {
                input = $("#event-ticket-category-" + event_ticket_category_id);
            }

            let amount = parseInt(input.val());

            let new_amount = addOrRemove(event_ticket_category_id, amount, "remove");
            input.val(new_amount);

            checkMaxForCategory(null, input);

        });

    }
);

function checkMaxForCategory(input, inputJqueryObj = null) {
    if (inputJqueryObj !== undefined && inputJqueryObj !== null) {
        input = document.getElementById(inputJqueryObj.prop('id'));
    }

    let inputJquery = $("input#" + input.id);
    let inputParent = inputJquery.closest('.event-ticket-category-list');
    $(".alert", inputParent).remove();
    if (!inputJquery.data('tickets-left-error')) {
        return;
    }

    let val = parseFloat(input.value),
        max = parseFloat(input.max),
        categoryOrderMax = parseFloat(input.getAttribute("data-max-purchase-qty"));

    if (val >= max) {
        let message = input.getAttribute("data-category-max-error");
        let alertType = (val == max) ? 'warning' : 'danger';
        if (max < categoryOrderMax) {
            message = input.getAttribute("data-tickets-left-error");
        }
        $(getAlertHtml(message, alertType)).appendTo(inputParent);
        $(".alert", inputParent).css('width', '100%');
    }
}