$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers' || p == 'organizer_event_subevent_customers' || p == 'organizer_event_period_tickets') {

            $('.order-part-refund-form-wrapper').hide();
            $('.order-info-wrapper').hide();
            $(document).on("click", ".order-part-refund", function (e) {
                e.preventDefault();
                let orderId = $(this).attr('data-order-id');
                let ticketId = $(this).attr('data-ticket-id');
                let orderPartRefundFormWrapper = $('#order-part-refund-form-wrapper-' + orderId + '-' + ticketId);
                let orderInfoWrapper = $('#order-info-wrapper-order-id-' + orderId + '-' + ticketId);
                if ($(orderPartRefundFormWrapper).is(":hidden")) {
                    orderPartRefundFormWrapper.show();
                } else {
                    orderPartRefundFormWrapper.hide();
                }
                if ($(orderInfoWrapper).is(":hidden")) {
                    orderInfoWrapper.show();
                } else {
                    orderInfoWrapper.hide();
                }
            });

            $(document).on("click", ".order-part-refund-btn", function () {
                $(".alert-danger").remove();
                let orderId = parseInt($(this).attr('data-order-id'));
                let orderReference = $(this).attr('data-order-reference');
                let ticketId = parseInt($(this).attr('data-ticket-id'));
                let eventId = parseInt($("body").attr("event_id"));
                let amount = parseFloat($('#data-order-part-refund-amount-' + orderId + '-' + ticketId).val());
                let orderSum = parseFloat($(this).attr('data-order-sum'));
                let partRefundNoAmountMessage = $(this).attr('data-no-amount');
                let partRefundTooHighAmountMessage = $(this).attr('data-too-high-amount');
                if (amount <= 0 || isNaN(amount)) {
                    appendAlert('#order-part-refund-form-' + orderId + '-' + ticketId, partRefundNoAmountMessage, 'danger', null, '#order-part-refund-form-wrapper-' + orderId + '-' + ticketId);
                    return false;
                }
                if (amount > orderSum) {
                    appendAlert('#order-part-refund-form-' + orderId + '-' + ticketId, partRefundTooHighAmountMessage, 'danger', null, '#order-part-refund-form-wrapper-' + orderId + '-' + ticketId);
                    return false;
                }
                partRefundOrder(orderId, eventId, amount, ticketId, orderReference);
            });

            function partRefundOrder(orderId, eventId, amount, ticketId, orderReference) {

                let ticketStatusPartRefunded = $('#ticket-list').attr('data-ticket-status-part-refunded');

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_customers/part_refund",
                    data: {
                        order_id: orderId,
                        event_id: eventId,
                        amount: amount
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            popupAlert();
                            $('.refunded-amount-order-id-' + orderId).html(data.message);

                            $('.ticket-status-' + orderReference).each(function () {
                                $(this).text(ticketStatusPartRefunded);
                            });

                        }

                    })
                    .fail(function (data) {

                        console.log(data);
                        console.log('Refund ERROR');

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            appendAlert('#order-part-refund-form-' + orderId + '-' + ticketId, data.responseJSON.error_message, 'danger', null, '#order-part-refund-form-wrapper-' + orderId + '-' + ticketId);
                        }

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

            }


        }

    }
);