$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");

        const isFeeTypePercent = ($("input[name='organizer_fee']").data('fee-type') == 1);

        if (p == 'organizer_event_ticket_category' && (a == 'new' || a == 'update')) {
            $(document).on("change", "#event_ticket_category_form #no_end_time", function () {
                let endDateContainer = $('#event-ticket-category-end-date-container');
                if ($(this).is(":checked") && !endDateContainer.hasClass('d-none')) {
                    endDateContainer.addClass('d-none');
                } else if (!$(this).is(":checked") && endDateContainer.hasClass('d-none')) {
                    endDateContainer.removeClass('d-none');
                }

                if ($(this).is(":checked")) {
                    const date = new Date()
                    date.setFullYear(date.getFullYear() + 100)
                    const day = ("0" + date.getDay()).slice(-2)
                    const month = ("0" + (date.getMonth() + 1)).slice(-2)
                    $('#event_ticket_category_form #end_date').val(date.getFullYear() + '-' + month + '-' + day);
                    $('#event_ticket_category_form #end_time').val('16:00');
                } else {
                    $('#event_ticket_category_form #end_date').val('');
                    $('#event_ticket_category_form #end_time').val('');
                }
            });
        }

        if (p == 'organizer_event_ticket_category' && a == 'new') {

            function addMaxPurchaseQty() {
                if ($('#max_purchase_qty').val() == '' && typeof ($('#max_tickets').val() * 1) == 'number' && ($('#max_tickets').val() * 1) > 0) {
                    let maxPurchaseQty = $('#max_purchase_qty').attr('max') * 1;
                    if (typeof maxPurchaseQty == 'number' && $('#max_tickets').val() >= maxPurchaseQty) {
                        $('#max_purchase_qty').val(maxPurchaseQty);
                    } else {
                        $('#max_purchase_qty').val($('#max_tickets').val());
                    }
                }
            }

            $(document).on("focusout ", '#max_tickets', function () {
                addMaxPurchaseQty();
            });

            function areaChecked() {
                let max_tickets = 0;
                $('.event-ticket-category-area-zone').each(function () {
                    if ($(this).prop("checked") == true) {
                        max_tickets += $(this).attr('data-people-limit') * 1;
                    }
                });
                $('#max_tickets').val(max_tickets);
                addMaxPurchaseQty();
            }

            $(document.body).on('click', ".event-ticket-category-area-zone", function () {
                areaChecked();
            });

            if ($('#event_ticket_category_form .ticket-category-zone-default-activated').length > 0) {
                areaChecked();
            }

        }

        let posIsActive = ($("input[name='pos_ticket_price_ex_vat']").length > 0);

        let hasChangedOrganizerFee = false;
        let hasChangedPosOrganizerFee = false;
        let hasChangedPriceInclVatAndFee = false;
        let hasChangedPosPriceInclVatAndFee = false;

        let initialOrganizerFee = $("input[name='organizer_fee']").data('initial-organizer-fee');
        if (!isNaN(initialOrganizerFee) && initialOrganizerFee > 0) {
            hasChangedOrganizerFee = true;
        }
        let initialPosOrganizerFee = $("input[name='pos_organizer_fee']").data('initial-pos-organizer-fee');
        if (!isNaN(initialPosOrganizerFee) && initialPosOrganizerFee > 0) {
            hasChangedPosOrganizerFee = true;
        }

        if (isFeeTypePercent) {
            calculatePriceInclVatAndFeeOnLoadForPercentFeeType();
            if (posIsActive) {
                calculatePriceInclVatAndFeeOnLoadForPercentFeeType(true);
            }
        } else {
            let updateFee = true;
            if ($("input[name='organizer_fee']").val() > 0) {
                updateFee = false;
            }
            calculatePriceInclVatAndFee(null, false, updateFee);
            if (posIsActive) {
                updateFee = true;
                if ($("input[name='pos_organizer_fee']").val() > 0) {
                    updateFee = false;
                }
                calculatePriceInclVatAndFee(null, true, updateFee);
            }
        }

        $(document).on("change", "input[name='ticket_price_ex_vat']", function () {
            $('.alert').remove();
            calculatePriceInclVatAndFee();
            hasChangedPriceInclVatAndFee = false;
        });
        $(document).on("change", "input[name='pos_ticket_price_ex_vat']", function () {
            $('.alert').remove();
            calculatePriceInclVatAndFee(null, true);
            hasChangedPosPriceInclVatAndFee = false;
        });
        $(document).on("change", "select[name='vat_code']", function () {
            $('.alert').remove();
            if (hasChangedPriceInclVatAndFee === true) {
                calculatePriceExVatAndFee();
            } else {
                calculatePriceInclVatAndFee();
            }
            if (posIsActive) {
                $("select[name='pos_vat_code']").val($(this).val());
                if (hasChangedPosPriceInclVatAndFee === true) {
                    calculatePriceExVatAndFee(null, true);
                } else {
                    calculatePriceInclVatAndFee(null, true);
                }
            }
        });
        $(document).on("change", "input[name='organizer_fee']", function () {
            $('.alert').remove();
            let fee = Number($(this).val());
            hasChangedOrganizerFee = true;
            if (hasChangedPriceInclVatAndFee === true) {
                if (isFeeTypePercent) {
                    calculatePriceExVatAndFeeForPercentFeeType(false, fee);
                } else {
                    calculatePriceExVatAndFee(fee);
                }
            } else {
                calculatePriceInclVatAndFee(fee);
            }

        });
        $(document).on("change", "input[name='pos_organizer_fee']", function () {
            $('.alert').remove();
            let fee = Number($(this).val());
            hasChangedPosOrganizerFee = true;
            if (hasChangedPosPriceInclVatAndFee === true) {
                if (isFeeTypePercent) {
                    calculatePriceExVatAndFeeForPercentFeeType(true, fee);
                } else {
                    calculatePriceExVatAndFee(fee, true);
                }
            } else {
                calculatePriceInclVatAndFee(fee, true);
            }

        });
        $(document).on("change", "input[name='ticket_price_incl_vat_and_fee']", function () {
            $('.alert').remove();
            hasChangedPriceInclVatAndFee = true;
            if (isFeeTypePercent) {
                calculatePriceExVatAndFeeForPercentFeeType();
            } else {
                calculatePriceExVatAndFee();
            }
        });
        $(document).on("change", "input[name='pos_ticket_price_incl_vat_and_fee']", function () {
            $('.alert').remove();
            hasChangedPosPriceInclVatAndFee = true;

            if (isFeeTypePercent) {
                calculatePriceExVatAndFeeForPercentFeeType(true);
            } else {
                calculatePriceExVatAndFee(null, true);
            }
        });

        function calculatePriceInclVatAndFee(fee = null, pos = false, updateFee = true) {
            if (pos === true) {
                let ticketPriceExVat = Number($("input[name='pos_ticket_price_ex_vat']").val());
                if(updateFee !== false) {
                    updateFeeFromTicketPriceExVat(ticketPriceExVat, true);
                }
                fee = Number($("input[name='pos_organizer_fee']").val());
                let vatPercent = Number($("select[name='vat_code'] option:selected").text());
                let ticketPriceInclVatAndFee = Number((ticketPriceExVat + fee) * (1 + (vatPercent / 100))).toFixed(2);
                $("input[name='pos_ticket_price_incl_vat_and_fee']").val(ticketPriceInclVatAndFee);
                if (!checkIfPriceInclVatIsHigherThanFee(ticketPriceInclVatAndFee, fee)) {
                    let errorMessage = $("input[name='pos_organizer_fee']").attr('data-error-message-amount-must-be-higher-than-fee');
                    appendAlert('#event-ticket-category-price-pos-wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                    return false;
                }
            } else {
                let ticketPriceExVat = Number($("input[name='ticket_price_ex_vat']").val());
                if(updateFee !== false) {
                    updateFeeFromTicketPriceExVat(ticketPriceExVat);
                }
                fee = Number($("input[name='organizer_fee']").val());
                let vatPercent = Number($("select[name='vat_code'] option:selected").text());
                let ticketPriceInclVatAndFee = Number((ticketPriceExVat + fee) * (1 + (vatPercent / 100))).toFixed(2);
                $("input[name='ticket_price_incl_vat_and_fee']").val(ticketPriceInclVatAndFee);
                if (!checkIfPriceInclVatIsHigherThanFee(ticketPriceInclVatAndFee, fee)) {
                    let errorMessage = $("input[name='organizer_fee']").attr('data-error-message-amount-must-be-higher-than-fee');
                    appendAlert('#event-ticket-category-price-wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                    return false;
                }
            }
        }

        function calculatePriceInclVatAndFeeOnLoadForPercentFeeType(pos = false) {
            let posPrefix = (pos) ? 'pos_' : '';
            let posPrefix2 = (pos) ? 'pos-' : '';

            let ticketPriceExVatInput = $("input[name='" + posPrefix + "ticket_price_ex_vat']");
            let feeInput = $("input[name='" + posPrefix + "organizer_fee']");
            let ticketPriceIncVatAndFeeInput = $("input[name='" + posPrefix + "ticket_price_incl_vat_and_fee']");

            const initialFee = Number(feeInput.data('initial-' + posPrefix2 + 'saved-fee-from-percent'));

            feeInput.val(Number(initialFee));
            ticketPriceIncVatAndFeeInput.val(Number(Number(ticketPriceExVatInput.val()) + Number(initialFee)));
        }

        function calculatePriceExVatAndFeeForPercentFeeType(pos = false, feeInputVal = 0) {
            let posPrefix = (pos) ? 'pos_' : '';
            let posPrefix2 = (pos) ? 'pos-' : '';

            let ticketPriceExVatInput = $("input[name='" + posPrefix + "ticket_price_ex_vat']");
            let feeInput = $("input[name='" + posPrefix + "organizer_fee']");
            let ticketPriceIncVatAndFeeInput = $("input[name='" + posPrefix + "ticket_price_incl_vat_and_fee']");
            let minimumFeeWithPercent = Number(feeInput.data('fee-minimum-sum'));

            if (ticketPriceIncVatAndFeeInput.val() == 0) {
                feeInput.val(0);
                ticketPriceExVatInput.val(0);
                return;
            }
            const minimumPriceIncVatAndFee = minimumFeeWithPercent + 1;
            if (ticketPriceIncVatAndFeeInput.val() < minimumPriceIncVatAndFee) {
                ticketPriceIncVatAndFeeInput.val(minimumPriceIncVatAndFee);
            }

            let fee = (feeInputVal > 0) ? Number(feeInputVal) : Number(feeInput.val());

            if (feeInputVal > 0) {
                if (fee < minimumFeeWithPercent && ticketPriceIncVatAndFeeInput.val() > 0) {
                    fee = minimumFeeWithPercent;
                    let errorMessage = $("input[name='" + posPrefix + "organizer_fee']").attr('data-error-message');
                    appendAlert('#event-ticket-category-price-' + posPrefix2 + 'wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                } else if (ticketPriceIncVatAndFeeInput.val() == 0) {
                    fee = 0;
                }
            }
            const ticketPriceInclVatAndFee = Number(ticketPriceIncVatAndFeeInput.val());
            const organizerFeePercent = Number(feeInput.data('fee-level-percent'));
            let currentFee = Number((ticketPriceInclVatAndFee / 100) * organizerFeePercent).toFixed(2);
            let userHaveChangedThisOrganizerFee = ((pos && hasChangedPosOrganizerFee) || (!pos && hasChangedOrganizerFee));
            if (!userHaveChangedThisOrganizerFee && currentFee < minimumFeeWithPercent) {
                let errorMessage = $("input[name='" + posPrefix + "organizer_fee']").attr('data-error-message');
                appendAlert('#event-ticket-category-price-' + posPrefix2 + 'wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                fee = minimumFeeWithPercent;
            } else if (!userHaveChangedThisOrganizerFee && currentFee > minimumFeeWithPercent) {
                fee = currentFee;
            }

            if (userHaveChangedThisOrganizerFee && currentFee > fee) {
                fee = currentFee;
            }

            const ticketPriceExVat = Number(ticketPriceInclVatAndFee - fee).toFixed(2);

            if (fee != Number(feeInput.val())) {
                feeInput.val(fee);
            }
            ticketPriceExVatInput.val(ticketPriceExVat);

        }

        function calculatePriceExVatAndFee(fee = null, pos = false) {
            if (pos === true) {
                let ticketPriceInclVatAndFee = Number($("input[name='pos_ticket_price_incl_vat_and_fee']").val());
                let vatPercent = Number($("select[name='vat_code'] option:selected").text());
                if (isPriceAndFeeAllowed(true) === false) {
                    let errorMessage = $("input[name='pos_organizer_fee']").attr('data-error-message');
                    appendAlert('#event-ticket-category-price-pos-wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                    return false;
                }
                updateFeeFromTicketPriceInclVatAndFee(ticketPriceInclVatAndFee, vatPercent, true);
                fee = Number($("input[name='pos_organizer_fee']").val());
                let ticketPriceExVat = Number((ticketPriceInclVatAndFee / (1 + (vatPercent / 100))) - fee);
                if (isFeeTypePercent) {
                    ticketPriceExVat = (ticketPriceInclVatAndFee - fee).toFixed(2)
                }
                $("input[name='pos_ticket_price_ex_vat']").val(ticketPriceExVat);
            } else {
                let ticketPriceInclVatAndFee = Number($("input[name='ticket_price_incl_vat_and_fee']").val());
                let vatPercent = Number($("select[name='vat_code'] option:selected").text());
                if (isPriceAndFeeAllowed() === false) {
                    let errorMessage = $("input[name='organizer_fee']").attr('data-error-message');
                    appendAlert('#event-ticket-category-price-wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                    return false;
                }
                updateFeeFromTicketPriceInclVatAndFee(ticketPriceInclVatAndFee, vatPercent);
                fee = Number($("input[name='organizer_fee']").val());
                let ticketPriceExVat = Number((ticketPriceInclVatAndFee / (1 + (vatPercent / 100))) - fee);
                if (isFeeTypePercent) {
                    ticketPriceExVat = (ticketPriceInclVatAndFee - fee).toFixed(2)
                }
                $("input[name='ticket_price_ex_vat']").val(ticketPriceExVat);
            }
        }

        function updateFeeFromTicketPriceExVat(ticketPriceExVat, pos = false) {
            let currentFee;
            let feeInput;
            if (pos) {
                feeInput = $("input[name='pos_organizer_fee']");
                currentFee = Number(feeInput.val());
            } else {
                feeInput = $("input[name='organizer_fee']");
                currentFee = Number(feeInput.val());
            }
            let fees = getStandardFees(pos);
            let fee = 0;
            let fee_amount = 0;

            if (isFeeTypePercent) {
                let minimumFeeWithPercent = Number(feeInput.data('fee-minimum-sum'));
                const organizerFeePercent = Number(feeInput.data('fee-level-percent'));
                fee = Number(ticketPriceExVat * organizerFeePercent / 100).toFixed(2);

                if ((hasChangedPosOrganizerFee && currentFee < minimumFeeWithPercent) || fee < minimumFeeWithPercent) {
                    fee = minimumFeeWithPercent;
                    let posPrefix = (pos) ? 'pos_' : '';
                    let posPrefix2 = (pos) ? 'pos-' : '';
                    let errorMessage = $("input[name='" + posPrefix + "organizer_fee']").attr('data-error-message');
                    appendAlert('#event-ticket-category-price-' + posPrefix2 + 'wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                    if (pos) {
                        hasChangedPosOrganizerFee = false;
                    } else {
                        hasChangedOrganizerFee = false;
                    }
                }
            } else {
                $.each(fees, function (key, value) {
                    if (ticketPriceExVat >= value.amount && value.amount >= fee_amount) {
                        fee_amount = value.amount;
                        if (value.is_percent === false) {
                            fee = Number(value.fee);
                        }
                        if (value.is_percent === true) {
                            fee = Number(ticketPriceExVat * value.fee_percent / 100).toFixed(2);
                        }
                    }
                });
                if (currentFee > fee) {
                    //hasChangedOrganizerFee = true;
                }
            }

            if (pos) {
                if (!hasChangedPosOrganizerFee || (hasChangedPosOrganizerFee && fee > currentFee)) {
                    $("input[name='pos_organizer_fee']").val(fee);
                    hasChangedPosOrganizerFee = false;
                }
            } else {
                if (!hasChangedOrganizerFee || (hasChangedOrganizerFee && fee > currentFee)) {
                    $("input[name='organizer_fee']").val(fee);
                    hasChangedOrganizerFee = false;
                }
            }
        }

        function updateFeeFromTicketPriceInclVatAndFee(ticketPriceInclVatAndFee, vatPercent, pos = false) {
            if (pos) {
                let fee = Number($("input[name='pos_organizer_fee']").val());
                let ticketPriceExVat = Number(ticketPriceInclVatAndFee / (1 + (vatPercent / 100)) - fee);
                if (!checkIfPriceInclVatIsHigherThanFee(ticketPriceInclVatAndFee, fee)) {
                    let errorMessage = $("input[name='pos_organizer_fee']").attr('data-error-message-amount-must-be-higher-than-fee');
                    appendAlert('#event-ticket-category-price-pos-wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                    return false;
                }
                updateFeeFromTicketPriceExVat(ticketPriceExVat, true);
            } else {
                let fee = Number($("input[name='organizer_fee']").val());
                let ticketPriceExVat = Number(ticketPriceInclVatAndFee / (1 + (vatPercent / 100)) - fee);
                if (!checkIfPriceInclVatIsHigherThanFee(ticketPriceInclVatAndFee, fee)) {
                    let errorMessage = $("input[name='organizer_fee']").attr('data-error-message-amount-must-be-higher-than-fee');
                    appendAlert('#event-ticket-category-price-wrapper', errorMessage, 'danger', null, '#event_ticket_category_form');
                    return false;
                }
                updateFeeFromTicketPriceExVat(ticketPriceExVat);
            }
        }

        function isPriceAndFeeAllowed(pos = false) {
            let ticketPriceInclVatAndFee;
            let vatPercent;
            let fee;
            let feeInput;
            if (pos) {
                feeInput = $("input[name='pos_organizer_fee']");
                ticketPriceInclVatAndFee = Number($("input[name='pos_ticket_price_incl_vat_and_fee']").val());
                vatPercent = Number($("select[name='vat_code'] option:selected").text());
                fee = Number($("input[name='pos_organizer_fee']").val());
            } else {
                feeInput = $("input[name='organizer_fee']");
                ticketPriceInclVatAndFee = Number($("input[name='ticket_price_incl_vat_and_fee']").val());
                vatPercent = Number($("select[name='vat_code'] option:selected").text());
                fee = Number($("input[name='organizer_fee']").val());
            }

            let ticketPriceExVat = Number((ticketPriceInclVatAndFee / (1 + (vatPercent / 100))) - fee);
            if (ticketPriceExVat < 0) {
                return false;
            }
            let fees = getStandardFees(pos);
            let currentFee = 0;

            if (isFeeTypePercent) {
                let minimumFeeWithPercent = Number(feeInput.data('fee-minimum-sum'));
                if (fee < minimumFeeWithPercent) {
                    return false;
                }

                const organizerFeePercent = Number(feeInput.data('fee-level-percent'));
                currentFee = Number(ticketPriceExVat * organizerFeePercent / 100).toFixed(2);
                if (currentFee > fee) {
                    fee = currentFee;
                }
                if (!hasChangedOrganizerFee && fee > currentFee) {
                    fee = currentFee;
                }
            } else {
                $.each(fees, function (key, value) {
                    if (ticketPriceExVat > Number(value.amount)) {
                        if (value.is_percent === false) {
                            currentFee = Number(value.fee);
                        } else {
                            currentFee = Number(ticketPriceExVat * value.fee_percent / 100).toFixed(2);
                        }
                        if (currentFee > fee) {
                            fee = currentFee;
                        }
                        if (!hasChangedOrganizerFee && fee > currentFee) {
                            fee = currentFee;
                        }
                    }
                });

                if (((ticketPriceExVat + fee) * (1 + (vatPercent / 100))) > ticketPriceInclVatAndFee) {
                    //return false;
                }
            }

            if (pos) {
                $("input[name='pos_organizer_fee']").val(fee);
            } else {
                $("input[name='organizer_fee']").val(fee);
            }
            return true;
        }

        function checkIfPriceInclVatIsHigherThanFee(ticketPriceInclVatAndFee, fee) {
            if (ticketPriceInclVatAndFee > 0 && ticketPriceInclVatAndFee < (fee + 1)) {
                return false;
            }
            return true;
        }

        function getStandardFees(pos = false) {
            let inputName = 'organizer_fee';
            if (pos) {
                inputName = 'pos_organizer_fee';
            }
            let inputElement = $("input[name='" + inputName + "']");
            let fees = [
                {
                    fee: inputElement.data('fee-level-0'),
                    amount: 0,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-1'),
                    amount: 1,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-2'),
                    amount: 51,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-3'),
                    amount: 101,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-4'),
                    amount: 251,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-5'),
                    amount: 451,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-6'),
                    amount: 701,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-7'),
                    amount: 951,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-8'),
                    amount: 1201,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-9'),
                    amount: 1501,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-10'),
                    amount: 2001,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-11'),
                    amount: 2751,
                    is_percent: false
                },
                {
                    fee: inputElement.data('fee-level-12'),
                    amount: 3501,
                    is_percent: false
                },
                {
                    fee_percent: inputElement.data('fee-level-percent'),
                    amount: 5001,
                    is_percent: true
                }
            ];
            return fees;
        }

        $(document).on("change", "input[name='multivalidation_ticket']", function () {
            if ($(this).is(":checked") && $('#multivalidation-ticket-wrapper').hasClass('d-none')) {
                $('#max_validations').val(1);
                $('#validation_days_after_end').val(0);
                $('#multivalidation-ticket-wrapper').removeClass('d-none')
            }
            if (!$(this).is(":checked") && !$('#multivalidation-ticket-wrapper').hasClass('d-none')) {
                $('#max_validations').val(1);
                $('#validation_days_after_end').val(0);
                $('#multivalidation-ticket-wrapper').addClass('d-none')
            }
        });

        function checkIfOptionCanBeActivated() {
            const periodTicket = $("#event_ticket_category_form #period_ticket");
            const periodTicketActivated = (periodTicket.length > 0 && periodTicket.is(':checked'));
            const ticketCategoryContainer = $("#event_ticket_category_form #ticket_category_container");
            const ticketCategoryContainerActivated = (ticketCategoryContainer.length > 0 && ticketCategoryContainer.is(':checked'));

            return (!(periodTicketActivated && ticketCategoryContainerActivated));
        }

        $(document).on("change", "input[name='period_ticket']", function () {
            if ($(this).is(":checked") && !checkIfOptionCanBeActivated()) {
                $(this).click();
                alert('Periodebillett kan ikke aktiveres samtidig som billettkategorigruppering.');
            } else {
                let periodTicketReminderContainer = $('#period-ticket-reminder-container');
                let periodTicketWrapper = $('#period-ticket-wrapper');

                if ($(this).is(":checked") && periodTicketWrapper.hasClass('d-none')) {
                    periodTicketWrapper.removeClass('d-none');
                    if (periodTicketReminderContainer.length > 0 && periodTicketReminderContainer.hasClass('d-none')) {
                        periodTicketReminderContainer.removeClass('d-none');
                    }
                }
                if (!$(this).is(":checked") && !periodTicketWrapper.hasClass('d-none')) {
                    periodTicketWrapper.addClass('d-none');
                    if (periodTicketReminderContainer.length > 0 && !periodTicketReminderContainer.hasClass('d-none')) {
                        periodTicketReminderContainer.addClass('d-none');
                        $('input#period_ticket_reminder').prop('checked', false).change();
                    }
                }
                $('#period_ticket_days').val('');
            }
        });

        $(document).on('change', '#period_ticket_reminder', function () {
            let periodTicketReminderWrapper = $('#period-ticket-reminder-wrapper');
            if ($(this).is(':checked')) {
                periodTicketReminderWrapper.removeClass('d-none');
            } else {
                periodTicketReminderWrapper.addClass('d-none');
            }
        });

        $(document).on("change", "#period_ticket_type", function () {
            const selectedType = $(this).children("option:selected").val();
            if (selectedType == 'valid_days') {
                $('#period_ticket_days_period_ticket_days_shifted').removeClass('d-none').addClass('d-none');
                $('#period_ticket_days_period_ticket_valid_days').removeClass('d-none');
            } else if (selectedType == 'year') {
                $('#period_ticket_days_period_ticket_valid_days').removeClass('d-none').addClass('d-none');
                $('#period_ticket_days_period_ticket_days_shifted').removeClass('d-none');
            }
        });

        $(document).on("change", "input[name='ticket_category_container']", function () {
            if ($(this).is(":checked") && !checkIfOptionCanBeActivated()) {
                $(this).click();
                alert('Billettkategorigruppering kan ikke aktiveres samtidig som periodebillett.');
            } else {
                const ticketCategoryContainerFormWrapper = $('#ticket_category_container_form_wrapper');
                const maxTicketsInput = $('#max_tickets');
                if ($(this).is(":checked") && ticketCategoryContainerFormWrapper.hasClass('d-none')) {
                    ticketCategoryContainerFormWrapper.removeClass('d-none');
                    $("input[name='discount_percent']").val('');
                    if (!$('#ticket-category-discount-fieldset').hasClass('d-none')) {
                        $('#ticket-category-discount-fieldset').addClass('d-none');
                    }

                    let ticketCategoryContainerSubProduct = $('input#ticket_category_container_sub_product');
                    if (!(ticketCategoryContainerSubProduct.length > 0 && ticketCategoryContainerSubProduct.is(':checked'))) {
                        $('#max_purchase_qty').closest('.form-row').addClass('d-none');
                        maxTicketsInput.val('100000');
                        maxTicketsInput.attr('max', '100000');
                        $('#max_purchase_qty').val('1');
                        $("input[name='min_purchase_qty']").val('1');
                        $("input[name='minimum_ticket_qty']").val('');
                    }
                }
                if (!$(this).is(":checked") && !ticketCategoryContainerFormWrapper.hasClass('d-none')) {
                    ticketCategoryContainerFormWrapper.addClass('d-none');
                    $('#max_purchase_qty').closest('.form-row').removeClass('d-none');
                    if (!(ticketCategoryContainerSubProduct.length > 0 && ticketCategoryContainerSubProduct.is(':checked'))) {
                        $('#ticket-category-discount-fieldset').removeClass('d-none');
                    }
                    maxTicketsInput.val('');
                    maxTicketsInput.attr('max', maxTicketsInput.data('max-tickets'));
                }
            }
        });

        $(document).on("change", "input#ticket_category_container_sub_product", function () {
            if ($(this).is(":checked") && $('#period_ticket').length > 0 && $('#period_ticket').is(":checked")) {
                $(this).click();
                if($('#ticket_category_container').is(":checked")){
                    $('#ticket_category_container').click();
                }
                alert('Billettkategorigruppering kan ikke aktiveres samtidig som periodebillett.');
            } else {
                const ticketCategoryContainerFormWrapper = $('#ticket_category_container_form_wrapper');
                let ticketCategoryContainerInput = $('input#ticket_category_container');
                if ($(this).is(":checked")) {
                    if (ticketCategoryContainerInput.length > 0 && !ticketCategoryContainerInput.is(":checked")) {
                        ticketCategoryContainerInput.click();
                    }
                    ticketCategoryContainerFormWrapper.removeClass('d-none');
                    $("input[name='ticket_price_ex_vat']").val('0').change();
                    let posTicketPriceExVat = $("input[name='pos_ticket_price_ex_vat']");
                    if (posTicketPriceExVat.length > 0) {
                        posTicketPriceExVat.val('0').change();
                    }

                    $('#event-ticket-category-price-wrapper').addClass('d-none');
                    $('#event-ticket-category-price-pos-wrapper').addClass('d-none');
                    $('#ticket_category_container_form_wrapper').addClass('sub-products-active');
                    if (!$('#ticket-category-discount-fieldset').hasClass('d-none')) {
                        $('#ticket-category-discount-fieldset').addClass('d-none');
                    }

                    $('#event_ticket_category_form #max_tickets').closest('.form-row').removeClass('d-none');
                }
                if (!$(this).is(":checked")) {
                    $('#event-ticket-category-price-wrapper').removeClass('d-none');
                    $('#event-ticket-category-price-pos-wrapper').removeClass('d-none');
                    $('#ticket_category_container_form_wrapper').removeClass('sub-products-active');
                    if (!(ticketCategoryContainerInput.length > 0 && ticketCategoryContainerInput.is(':checked')) && $('#ticket-category-discount-fieldset').hasClass('d-none')) {
                        $('#ticket-category-discount-fieldset').removeClass('d-none');
                    }
                }
            }
        });

        $('.container-group-category-wrapper .container-group-category-select').select2({
            theme: 'bootstrap4'
        });

        $(document).on("click", ".container-group-category-wrapper .container-group-category-remove", function () {
            $(this).closest('.container-group-category-wrapper').remove();
        });

        $(document).on("click", ".ticket-category-container-selection-group .category-container-group-remove", function () {
            $(this).closest('.ticket-category-container-selection-group').remove();
        });

        let event_ticket_category_container_group_lock = false;
        $(document).on("click", "#ticket_category_container_form_wrapper #category-container-group-category-add", function () {
            if (!event_ticket_category_container_group_lock) {
                const eventId = $("#event_id").val();
                const groupId = $('.category-container-group-category-sub-add:last').data("group-id") * 1 + 1;
                event_ticket_category_container_group_lock = true;

                $.ajax({
                    type: 'GET',
                    url: "/ajax/organizer_event_ticket_category_container/group",
                    data: {
                        event_id: eventId,
                        group_id: groupId
                    }
                })
                    .done(function (data) {
                        if (data.message !== undefined && data.message !== null) {
                            $(".ticket-category-container-selection-group:last").after(data.message);

                            $('.container-group-category-wrapper .container-group-category-select').select2({
                                theme: 'bootstrap4'
                            });
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#category-container-group-category-add', data.responseJSON.error_message, 'danger', null, '#ticket_category_container_form_wrapper')
                        }
                        event_ticket_category_container_group_lock = false;

                    })
                    .always(function () {
                        event_ticket_category_container_group_lock = false;
                    });
            }
        });

        let event_ticket_category_container_group_sub_add_lock = false;
        $(document).on("click", ".ticket-category-container-selection-group .category-container-group-category-sub-add", function () {
            if (!event_ticket_category_container_group_sub_add_lock) {
                const eventId = $("#event_id").val();
                const groupId = $(this).data("group-id");
                const button = $(this);
                event_ticket_category_container_group_sub_add_lock = true;

                $.ajax({
                    type: 'GET',
                    url: "/ajax/organizer_event_ticket_category_container/group_select",
                    data: {
                        event_id: eventId,
                        group_id: groupId
                    }
                })
                    .done(function (data) {
                        if (data.message !== undefined && data.message !== null) {
                            $(button).before(data.message);

                            $('.container-group-category-wrapper .container-group-category-select').select2({
                                theme: 'bootstrap4'
                            });
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#category-container-group-category-sub-add-', data.responseJSON.error_message, 'danger')
                        }
                        event_ticket_category_container_group_sub_add_lock = false;

                    })
                    .always(function () {
                        event_ticket_category_container_group_sub_add_lock = false;
                    });
            }
        });

        $(document).on('change', "#event_ticket_category_form input#status", function () {
            if ($(this).is(':checked')) {
                $('.category-platform-display').removeClass('d-none');
            } else {
                $('.category-platform-display').addClass('d-none');
            }
        });

    }
);