$(document).ready(
    function () {

        let language = $('body').attr('lang');
        let page = $('body').attr('p');
        let date = new Date();

        // Changing to native datepicker
        $(document).on('change', '.datepicker-range.datepicker-range-from', function () {
            let datePickerConnection = $(this).data('datepicker-connection-id');
            if (datePickerConnection !== undefined) {
                let endDate = $(".datepicker-range.datepicker-range-to[data-datepicker-connection-id='" + datePickerConnection + "']");
                if (endDate.length > 0) {
                    let newStartDate = $(this).val();
                    let parts = endDate.val().split('-');
                    const endDateObj = new Date(parts[0], parts[1], parts[2]);
                    parts = newStartDate.split('-');
                    const dateNew = new Date(parts[0], parts[1], parts[2]);
                    const diffTime = (endDateObj - dateNew);
                    const diffDays = Math.ceil(Math.abs(diffTime) / (86400000));

                    if (!isNaN(diffDays)) {
                        const parentForm = $(this).closest("form");
                        if (diffDays > 1095) {
                            endDate.val('');
                            popupAlert(5000, 'info', parentForm.data('date-range-error-message'));
                        } else {
                            parentForm.submit();
                        }
                    }
                }
            }
        });

        $(document).on('change', '.datepicker-range.datepicker-range-to', function () {
            let datePickerConnection = $(this).data('datepicker-connection-id');
            if (datePickerConnection !== undefined) {
                let startDate = $(".datepicker-range.datepicker-range-from[data-datepicker-connection-id='" + datePickerConnection + "']");
                if (startDate.length > 0) {
                    let newEndDate = $(this).val();
                    let parts = startDate.val().split('-');
                    const endDateObj = new Date(parts[0], parts[1], parts[2]);
                    parts = newEndDate.split('-');
                    const dateNew = new Date(parts[0], parts[1], parts[2]);
                    const diffTime = (endDateObj - dateNew);
                    const diffDays = Math.ceil(Math.abs(diffTime) / (86400000));

                    if (!isNaN(diffDays)) {
                        const parentForm = $(this).closest("form");
                        if (diffDays > 1095) {
                            startDate.val('');
                            popupAlert(5000, 'info', parentForm.data('date-range-error-message'));
                        } else {
                            parentForm.submit();
                        }
                    }
                }
            }
        });

        if ($.fn.pickadate !== undefined && $.fn.pickadate !== null) {

            if (language === 'en') {
                $.extend($.fn.pickadate.defaults, {
                    firstDay: 0,
                    monthsFull: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    today: 'Today',
                    clear: 'Clear',
                    close: 'Close'
                });
                jQuery.extend(jQuery.fn.pickatime.defaults, {clear: "Clear"});
            } else {
                $.extend($.fn.pickadate.defaults, {
                    firstDay: 1,
                    monthsFull: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
                    weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
                    today: 'Idag',
                    clear: 'Nullstill',
                    close: 'Lukk'
                });
                jQuery.extend(jQuery.fn.pickatime.defaults, {clear: "Nullstill"});
            }

            let $startDate = $(".datepicker-start-date").pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                min: [date.getFullYear(), date.getMonth(), date.getDate()],
                editable: true
            });

            let $endDate = $(".datepicker-end-date").pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                min: [date.getFullYear(), date.getMonth(), date.getDate()],
                editable: true
            });

            let $startTime = $(".datepicker-start-time").pickatime({
                format: 'H:i',
                formatSubmit: 'H:i',
                editable: true
            });

            let $endTime = $(".datepicker-end-time").pickatime({
                format: 'H:i',
                formatSubmit: 'H:i',
                editable: true
            });

            let $startDateStatistic = $(".datepicker-start-date-statistic").pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                editable: true
            });

            let $endDateStatistic = $(".datepicker-end-date-statistic").pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                editable: true
            });

            let $startDateVisitTracker = $(".datepicker-start-date-visit-tracker").pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                editable: true
            });

            let $endDateVisitTracker = $(".datepicker-end-date-visit-tracker").pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                editable: true
            });

            let pickerStartDate = $startDate.pickadate('picker');
            let pickerEndDate = $endDate.pickadate('picker');
            let pickerStartTime = $startTime.pickatime('picker');
            let pickerEndTime = $endTime.pickatime('picker');
            let pickerStartDateStatistic = $startDateStatistic.pickadate('picker');
            let pickerEndDateStatistic = $endDateStatistic.pickadate('picker');
            let pickerStartDateVisitTracker = $startDateVisitTracker.pickadate('picker');
            let pickerEndDateVisitTracker = $endDateVisitTracker.pickadate('picker');

            $(document).on("click", ".datepicker-start-date", function () {
                pickerStartDate.open();
            });

            $(document).on("click", ".datepicker-end-date", function () {
                pickerEndDate.set('min', pickerStartDate.get());
                pickerEndDate.open();
            });

            $(document).on("click", ".datepicker-start-time", function () {
                pickerStartTime.open();
            });

            $(document).on("click", ".datepicker-end-time", function () {
                pickerEndTime.open();
            });

            $(document).on("click", ".datepicker-start-date-visit-tracker", function () {
                pickerStartDateVisitTracker.open();
            });

            $(document).on("click", ".datepicker-end-date-visit-tracker", function () {
                pickerEndDateVisitTracker.set('min', pickerStartDateVisitTracker.get());
                pickerEndDateVisitTracker.open();
            });

            if (page === 'admin_payout_reports') {

                $(document).on("click", ".datepicker-end-date-statistic", function () {

                    let startDate = new Date(pickerStartDateStatistic.get());
                    let endDate = new Date();

                    pickerEndDateStatistic.set('min', new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
                    pickerEndDateStatistic.set('max', new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1));
                    pickerEndDateStatistic.open();

                });

            } else {

                $('.datepicker-start-date-statistic').each(function () {
                    let $startDate = $(this).closest('.form-row').find(".datepicker-start-date-statistic").pickadate({
                        format: 'yyyy-mm-dd',
                        formatSubmit: 'yyyy-mm-dd',
                        min: [date.getFullYear(), date.getMonth(), date.getDate()],
                        editable: true
                    });
                    let $endDate = $(this).closest('.form-row').find(".datepicker-end-date-statistic").pickadate({
                        format: 'yyyy-mm-dd',
                        formatSubmit: 'yyyy-mm-dd',
                        min: [date.getFullYear(), date.getMonth(), date.getDate()],
                        editable: true
                    });
                    let pickerEndDateStatistic = $endDate.data('pickadate');
                    $startDate.on('click', function (ev) {
                        let startDate = new Date(pickerEndDateStatistic.get());
                        let year = startDate.getFullYear();
                        let month = startDate.getMonth();
                        let day = startDate.getDate();
                        let oneYearPriorToEndDate = new Date(year - 2, month, day);

                        ev.stopPropagation();
                        ev.preventDefault();
                        let pickerStartDateStatistic = $startDate.data('pickadate');
                        pickerStartDateStatistic.set('min', oneYearPriorToEndDate);
                        pickerStartDateStatistic.set('max', pickerEndDateStatistic.get());
                        pickerStartDateStatistic.open();
                    });
                });

                $('.datepicker-end-date-statistic').each(function () {
                    let $endDate = $(this).closest('.form-row').find(".datepicker-end-date-statistic").pickadate({
                        format: 'yyyy-mm-dd',
                        formatSubmit: 'yyyy-mm-dd',
                        min: [date.getFullYear(), date.getMonth(), date.getDate()],
                        editable: true
                    });
                    let $startDate = $(this).closest('.form-row').find(".datepicker-start-date-statistic").pickadate({
                        format: 'yyyy-mm-dd',
                        formatSubmit: 'yyyy-mm-dd',
                        min: [date.getFullYear(), date.getMonth(), date.getDate()],
                        editable: true
                    });
                    let pickerStartDateStatistic = $startDate.data('pickadate');
                    $endDate.on('click', function (ev) {

                        let startDate = new Date(pickerStartDateStatistic.get());
                        let year = startDate.getFullYear();
                        let month = startDate.getMonth();
                        let day = startDate.getDate();
                        let oneYearFromStartDate = new Date(year + 1, month, day);

                        ev.stopPropagation();
                        ev.preventDefault();
                        let pickerEndDateStatistic = $endDate.data('pickadate');
                        pickerEndDateStatistic.set('min', pickerStartDateStatistic.get());
                        pickerEndDateStatistic.set('max', oneYearFromStartDate);
                        pickerEndDateStatistic.open();
                    });
                });


                /*$(document).on("click", ".datepicker-start-date-statistic", function () {

                    let startDate = new Date(pickerEndDateStatistic.get());
                    let year = startDate.getFullYear();
                    let month = startDate.getMonth();
                    let day = startDate.getDate();
                    let oneYearPriorToEndDate = new Date(year - 1, month, day);

                    pickerStartDateStatistic.set('min', oneYearPriorToEndDate);
                    pickerStartDateStatistic.set('max', pickerEndDateStatistic.get());
                    pickerStartDateStatistic.open();

                });

                $(document).on("click", ".datepicker-end-date-statistic", function () {

                    let startDate = new Date(pickerStartDateStatistic.get());
                    let year = startDate.getFullYear();
                    let month = startDate.getMonth();
                    let day = startDate.getDate();
                    let oneYearFromStartDate = new Date(year + 1, month, day);

                    pickerEndDateStatistic.set('min', pickerStartDateStatistic.get());
                    pickerEndDateStatistic.set('max', oneYearFromStartDate);
                    pickerEndDateStatistic.open();

                });*/
            }

        }

    }
);