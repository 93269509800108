$(document).ready(
    function () {

        $(document).on("click", ".event-subevent-cancel", function () {

            let eventSubeventId = $(this).data("event-subevent-id");
            //let confirmMessage = $(this).data("confirm-message");

            /*if (!confirm(confirmMessage)) {
                return false;
            }*/

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event_subevent/cancel",
                data: {
                    event_subevent_id: eventSubeventId
                }
            })
                .done(function (data) {

                    if (data == true) {
                        popupAlert();

                        $("#subevent-" + eventSubeventId).fadeOut(1000);
                        /*setTimeout(function () {
                            document.location.reload();
                            //$("#subevent-" + eventSubeventId).remove();
                        }, 1000);*/
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                    if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                        $("#subevent-error").parents('tr').remove();
                        $('#subevent-' + eventSubeventId).after('<tr><td colspan="4" id="subevent-error"></td></tr>');
                        appendAlert('#subevent-error', data.responseJSON.error_message, 'danger', null, '#subevent-table');
                    }

                });

            return false;

        });

        $(document).on("click", ".close", function () {
            $("#subevent-error").parents('tr').remove();
        });

    }
);