$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if ((p == 'organizer_event_form' || p == 'organizer_event_subevent_form') && a == 'show') {

            $('.event-form-statistic').change(function () {
                ajaxSubmitAndGetEventFormStatistic();
            });

            if ($('#period-week').prop("checked") === false) {
                $('#period-week').prop("checked", true);
            }
            ajaxSubmitAndGetEventFormStatistic();

            function ajaxSubmitAndGetEventFormStatistic() {

                let event_id = $("body").attr("event_id");
                let start = $('#start-date').val();
                let end = $('#end-date').val();
                let period = $('input[name=period]:checked').val();
                let order_origin = $("#order_origin").val();
                let data = {
                    event_id,
                    start,
                    end,
                    period,
                    order_origin
                };

                if (p == 'organizer_event_subevent_form' && $("body").attr('event_subevent_id') !== undefined && $("body").attr('event_subevent_id') != '') {
                    data.event_subevent_id = $("body").attr('event_subevent_id');
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_form/statistic",
                    data: data
                })
                    .done(function (data) {

                        $('#event-form-question-wrapper').empty();
                        $('#event-form-export').attr('href', data.event_form_download_token);
                        $('#event-form-export-details-tabs').attr('href', data.event_form_download_token_details_tabs);
                        $('#event-form-export-details-columns').attr('href', data.event_form_download_token_details_columns);
                        $.each(data, function (key, value) {
                            $('#event-form-question-wrapper').append(value.html);
                            if (value.table == 'true') {
                                $('#event-bar-graph-' + value.question_id).html(value.answer);
                            } else {
                                barChart(value);
                            }
                        });

                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }


            function barChart(primalData) {

                Highcharts.chart('event-bar-graph-' + primalData.question_id, {
                    chart: {type: 'column', styledMode: true},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date
                    },
                    yAxis: {
                        title: {text: primalData.metric}
                    },
                    credits: {enabled: false},
                    series: primalData.answer
                });

            }

        }
    });