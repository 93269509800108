$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'event' && a == 'show') {


            $('.event-subevent-calendar-box-icon').click(function (e) {

                e.preventDefault();
                let redirectUrl = $(this).parents().attr('href');

                $.ajax({
                    type: 'GET',
                    url: "/ajax/order/cancel_reservation"
                })
                    .done(function (data) {
                        removeDiscountCookie();
                        document.location.href = redirectUrl;
                    })

            });

            function removeDiscountCookie() {
                let daysForCookieToExpire = 0;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "discount = ;expires=" + cookieExpires + "; path=/; SameSite=strict";
            }

        }

    }
);

