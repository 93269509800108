$(document).ready(
    function () {

        $(document).on("click", ".delete-event-attachment", function () {

            let event_id = $("body").attr("event_id");
            let confirm_message = $(this).data("confirm-message");

            if (!confirm(confirm_message)) {
                return false;
            }

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event/delete_attachment",
                data: {
                    event_id: event_id
                }
            })
                .done(function (data) {

                    if (data.message !== undefined && data.message !== null) {
                        popupAlert();
                        $('#event_register_form .delete-event-attachment').remove();
                        $("#event_register_form input[name='attachment_file_name']").val('');
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                });

            return false;

        });

    }
);