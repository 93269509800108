$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event_form' && a == 'edit') {

            let eventFormQuestionIdToDelete = [];
            $(document).on("click ", '.delete-event-form-question-button', function () {
                if (!$(this).data('new-event-form-question')) {
                    let newQuestionCheck = $(this).data('new-event-form-question');
                    let confirmMessage = $(this).data('new-event-form-question-message');
                    if (newQuestionCheck == false) {
                        if (!confirm(confirmMessage)) {
                            return false;
                        }
                    }
                }
                let eventFormQuestionId = $(this).closest('div.event-form-question').data('event-form-question-id');
                if (eventFormQuestionId != false) {
                    eventFormQuestionIdToDelete.push(eventFormQuestionId);
                }
                $(this).closest('div.event-form-question').remove();
            });


            $(document).on("click ", '#event-form-register-button', function () {
                    deleteEventFormQuestion(eventFormQuestionIdToDelete);
                    let eventFormQuestionListOrder = 0;
                    let eventFormQuestionArray = $('div.event-form-question');
                    eventFormQuestionArray.each(function () {
                        eventFormQuestionListOrder += 1;
                        let eventFormQuestionForm = $(this).children('form.event-form-question-form');
                        let eventFormInput = $("<input>")
                            .attr("type", "hidden")
                            .attr("name", "event_form[" + eventFormQuestionForm.data('event-form-question-index') + "][list_order]")
                            .val(eventFormQuestionListOrder);
                        eventFormQuestionForm.append(eventFormInput);
                        eventFormQuestionForm.submit();
                    });
                }
            );


            function deleteEventFormQuestion(params) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_form/delete",
                    data: {
                        event_form_question_id: params
                    }
                }).done(function (data) {

                }).fail(function (data) {
                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }
                });
            }

            $(document).on("submit", '.event-form-question-form', function () {
                let event_id = $("body").attr("event_id");
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_form/new",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                }).done(function (data) {

                    if (data.message !== undefined && data.message !== null) {
                        prependAlert('#event-form-register-button-wrapper', data.message, 'success', null, '#event-form-question-form');
                        $('.card-box-to-hide').hide();
                        $('#add-event-form-age-button').hide();
                        $('#add-event-form-country-button').hide();
                        $('#add-event-form-question-option-button').hide();
                        $('#event-form-register-button').hide();

                        setTimeout(function () {
                            document.location.href = "/organizer/event/" + event_id + "/form";
                        }, 2000);
                    }

                }).fail(function (data) {
                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }
                });

                return false;
            });
        }
    })
;





