$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'organizer_settings_users') {
            $(document).on("change", "#email", function () {
                $("#newUser .alert").remove();
                getBouncerPasswordCheckbox();
            });
            $(document).on("change", "#role", function () {
                getBouncerPasswordCheckbox();
            });

            function isUserAlreadyRegisteredCheck(email) {
                let emailCheck = false;
                isUserAlreadyRegistered(email, function (callback) {
                    emailCheck = callback;
                });
                return emailCheck;
            }

            function getBouncerPasswordCheckbox() {
                let roleBouncer = 3;
                let role = $('#role option:selected').val();
                let email = $('#email').val();
                let isUserRegistered = isUserAlreadyRegisteredCheck(email);
                if (role == roleBouncer && !isUserRegistered) {
                    if ($('#bouncer-set-password-wrapper').hasClass('d-none')) {
                        $('#bouncer-set-password-wrapper').removeClass('d-none');
                    }
                } else {
                    removePasswordFields();
                    if ($('#bouncer-set-password').is(":checked")) {
                        $('#bouncer-set-password').prop("checked", false);
                    }
                    if (!$('#bouncer-set-password-wrapper').hasClass('d-none')) {
                        $('#bouncer-set-password-wrapper').addClass('d-none');
                    }
                }
            }

            $(document).on("change", ".bouncer-set-password-trigger", function () {
                getBouncerPasswordFields();
            });

            function getBouncerPasswordFields() {
                if ($('#bouncer-set-password').is(":checked")) {
                    if ($('#password-wrapper').hasClass('d-none')) {
                        $('#password-wrapper').removeClass('d-none');
                        $("#password").prop('required', true);
                        $("#password_check").prop('required', true);
                    }
                } else {
                    removePasswordFields();
                }
            }

            function removePasswordFields() {
                $('#password').val(null);
                $('#password_check').val(null);
                $("#password").prop('required', false);
                $("#password_check").prop('required', false);
                if (!$('#password-wrapper').hasClass('d-none')) {
                    $('#password-wrapper').addClass('d-none');
                }
            }

            function isUserAlreadyRegistered(email, callback) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_settings_users/get_user",
                    async: false,
                    data: {
                        email: email
                    }
                })
                    .done(function (data) {
                        callback(data);
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

        }


        let organizer_new_user_ajax_lock = false;

        $(document).on("submit", "#settings_register_new_user_form", function () {

            $("#newUser .alert").remove();

            if (!organizer_new_user_ajax_lock) {

                organizer_new_user_ajax_lock = true;

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_settings_users/new_user",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.newUser !== undefined && data.newUser != null) {
                            if (data.password !== undefined && data.password != null) {
                                document.location.href = "/organizer/settings/users?success=1&user=" + data.newUser + '&password=' + data.password;
                            } else {
                                document.location.href = "/organizer/settings/users?success=1&user=" + data.newUser;
                            }

                        }
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            appendAlert('#div_settings_register_new_user', data.responseJSON.error_message, 'danger', null, '#settings_register_new_user_form');
                        }

                        organizer_new_user_ajax_lock = false;

                    })
                    .always(function () {
                        organizer_new_user_ajax_lock = false;
                    });

            }

            return false;

        });

    }
)
;