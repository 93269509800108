$(document).ready(
    function () {
        let p = $("body").attr("p");
        if (p == 'organizer_events') {
            let event_delete_ajax_lock = false;
            $(document).on("click", ".event-delete-btn", function () {
                if (!event_delete_ajax_lock && confirm($(this).data('confirm-message'))) {
                    event_delete_ajax_lock = true;
                    let eventId = $(this).attr("data-event-id");
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_event/delete",
                        data: {
                            event_id: eventId
                        }
                    })
                        .done(function (data) {
                            if (data.status !== undefined && data.status == 1) {
                                popupAlert();
                                $('#event-list ul.list-group[data-event-id="' + eventId + '"]').fadeOut(1000);
                                $('#event-list table tr[data-event-id="' + eventId + '"]').fadeOut(1000);
                            } else if (data.message !== undefined && data.message != '') {
                                popupAlert(3000, 'danger', data.message);
                            } else {
                                popupAlert(3000, 'danger');
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            popupAlert(500, 'danger');
                            event_delete_ajax_lock = false;
                        })
                        .always(function () {
                            event_delete_ajax_lock = false;
                        });
                }
                return false;
            });
        }
    }
);