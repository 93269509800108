$(document).ready(
    function () {
        const body = $("body");
        let p = body.attr("p");
        let a = body.attr("a");
        if (p == 'product_groups' && (a == 'new' || a == 'edit')) {
            let deactivate = false;
            let product_group_save_ajax_lock = false;
            $(document).on("submit", "#product_group_form", function () {
                if (!product_group_save_ajax_lock) {
                    product_group_save_ajax_lock = true;
                    let formData = new FormData(document.getElementById('product_group_form'));
                    if (deactivate) {
                        formData.append('deactivate', '1');
                        deactivate = false;
                    }

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/product_groups/save",
                        data: formData,
                        async: true,
                        cache: false,
                        processData: false,
                        contentType: false
                    })
                        .done(function (data) {
                            if (data.message !== undefined && data.message !== null) {
                                prependAlert('#product_group_save_btn_wrapper', data.message, 'success', null, '#product_group_form');
                                setTimeout(function () {
                                    document.location.href = "/organizer/settings/product_groups";
                                }, 2000);
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#product_group_save_btn_wrapper', data.responseJSON.error_message, 'danger', null, '#product_group_form')
                            }
                            product_group_save_ajax_lock = false;

                        })
                        .always(function () {
                            product_group_save_ajax_lock = false;
                        });
                }
                return false;
            });

            $(document).on('click', '#product_group_deactivate_btn', function () {
                deactivate = true;
                $('#product_group_form input#product_group_status').val('0');
                $('#product_group_form').submit();
            });
        }
    }
);