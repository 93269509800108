$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers' || p == 'organizer_event_subevent_customers' || p == 'organizer_event_period_tickets') {

            $(document).on("click", ".ticket-deactivate", function (e) {

                e.preventDefault();
                let deactivateButton = $(this);
                let confirmText = $(this).attr("data-ticket-deactivate-text");
                if (!confirm(confirmText)) {
                    return false;
                }
                deactivateTicket(deactivateButton);
            });

        }

        function deactivateTicket(deactivateButton) {

            let ticketId = deactivateButton.attr('data-ticket-deactivate');
            let ticketStatusDeactivated = $('#ticket-list').attr('data-ticket-status-deactivated');
            let ticketStatusDeactivatedMessage = $('#ticket-list').attr('data-ticket-status-deactivated-message');
            let ticketStatus = $('#ticket-status-' + ticketId);
            let sendButton = $('#ticket-send-' + ticketId);
            let downloadButton = $('#ticket-download-' + ticketId);

            $.ajax({
                type: 'POST',
                url: "/ajax/organizer_event_customers/deactivate",
                data: {
                    ticket_id: ticketId
                }
            })
                .done(function (data) {

                    if (data.message === true) {
                        deactivateButton.remove();
                        sendButton.remove();
                        downloadButton.remove();
                        ticketStatus.text(ticketStatusDeactivated);
                        prependAlert('#modal-footer-' + ticketId, ticketStatusDeactivatedMessage, 'success', null, '#modal-footer')
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                })
        }

    }
);

