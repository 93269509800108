$(document).ready(
    function () {


        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'event' && a == 'show') {

            function activateSelect2() {
                if ($('#checkout-customer-missing-fields select[name=country]:not(.select2-hidden-accessible)').length > 0) {
                    $('#checkout-customer-missing-fields select[name=country]').select2({
                        theme: 'bootstrap4',
                        placeholder: ""
                    });
                }
            }

            if (!$('#scene-wrapper-order').hasClass('d-none')) {
                $('#scene-wrapper-order').addClass('d-none');
            }

            let reservation_ajax_lock = false;

            let eventId = $("#event-id").val();
            let eventTicketCategoryList = "#event-ticket-category-list";
            let orderForm = $("#order-form");
            let restrictionCodeForm = $("#restriction-code-form");
            let periodeTicketAsGiftFormButtonDiv = $('#period-ticket-as-gift-form-button-div');
            let periodeTicketAsGiftForm = $('#period-ticket-as-gift-form');
            let periodTicketAsGiftRedeemFormContainer = $('#period-ticket-as-gift-redeem-form-container');
            let hideOrderForm = $("#hide-order-form");
            let orderSummary = $("#order-summary");
            let orderTerms = $("#order-terms");
            let orderButton = $("#order-register-button");
            let cancelButton = $("#order-cancel-button");
            let nextButton = $("#order-next-button");
            let countDownId = $('#order-count-down-timer');
            let expirationTime = parseInt(countDownId.attr("data-reservation-time")) * 1000;
            let expirationTimeText = countDownId.attr("data-reservation-time-text");
            let expirationText = countDownId.attr("data-reservation-text");
            let reservationCountDownInterval = null;
            let reservedEventId = null;
            let eventTicketCategoryId = null;
            let eventSubeventId = null;
            if ($("#subevent-id").length) {
                eventSubeventId = $("#subevent-id").val();
            }
            let discount;
            let event_form_html;

            let organizer_id = parseInt($("body").attr("organizer_id"));
            let event_id = parseInt($("body").attr("event_id"));

            if (typeof $("body").attr("reservation_event_id") !== typeof undefined && $("body").attr("reservation_event_id") !== false) {
                reservedEventId = $("body").attr("reservation_event_id");
            }

            if (eventId === reservedEventId) {
                continueReservation();
            } else {
                startReservation();
            }

            let numberOfSeats = 0;
            let numberOfSeatsReserved = 0;
            let seatIdArray = null;
            let reservedSeatIdArray = null;
            let areaZoneId;
            let areaZoneVersionId;
            let numberOfTickets;
            let isStandingZone = false;
            let chooseForMe = false;

            let isMobile = false;
            let thisIsMobile = false
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
                thisIsMobile = true;
            }

            /*if ($(".event-ticket-category-list")[0] && $('#scene-wrapper-order').length) {
                let numberOfEventTicketCategories = 0;
                $('.event-ticket-category-list').each(function () {
                    numberOfEventTicketCategories = numberOfEventTicketCategories + 1;
                });
                if (numberOfEventTicketCategories == 1) {
                    $('.event-ticket-category-list').each(function () {
                        eventTicketCategoryId = $(this).find('button.event-ticket-category-with-area-button').data("event-ticket-category-id");
                        if (!$(this).find('div.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                            $(this).find('div.event-ticket-category-with-area-button-wrapper').addClass('d-none');
                        }
                        if (eventId !== reservedEventId) {
                            openAreaZone(eventTicketCategoryId);
                        }
                        return false;
                    });
                }
            }*/

            function hideDuringReservation() {
                if (periodTicketAsGiftRedeemFormContainer.length > 0 && !periodTicketAsGiftRedeemFormContainer.hasClass('d-none')) {
                    periodTicketAsGiftRedeemFormContainer.addClass('d-none');
                }
            }

            function showAfterCancellingReservation() {
                if (periodTicketAsGiftRedeemFormContainer.length > 0 && periodTicketAsGiftRedeemFormContainer.hasClass('d-none')) {
                    periodTicketAsGiftRedeemFormContainer.removeClass('d-none');
                }
            }

            $(document).on("click", ".container-category-select", function () {
                const eventTicketCategoryId = $(this).data('container-event-ticket-category-id');
                if (eventTicketCategoryId !== undefined) {
                    const containerCategoriesWrapper = $(".container-category-list-wrapper[data-container-event-ticket-category-id='" + eventTicketCategoryId + "']");
                    if (containerCategoriesWrapper !== undefined) {
                        if (containerCategoriesWrapper.is(':visible')) {
                            $('input', $(this).closest('.ticket-qty-container')).val('0');
                            containerCategoriesWrapper.hide();
                            $(':input', containerCategoriesWrapper).val('0');
                        } else {
                            $('input', $(this).closest('.ticket-qty-container')).val('1');
                            containerCategoriesWrapper.show();
                        }
                    }
                }
            });

            $(document).on("click", "#choose-seat-for-me-button", function () {
                chooseForMe = true;

                $('#div_order_buttons').find('.alert:not(.alert-static)').remove();
                reserveTicketsWithAreaZoneV2();
            });

            $(document).on("click", "#see-area-zone-button", function () {
                chooseForMe = false;
                if ($('#event-ticket-category-area-zone').hasClass('d-none')) {
                    $('#event-ticket-category-area-zone').removeClass('d-none');
                }
                if (!$('#see-area-zone-button').hasClass('d-none')) {
                    $('#see-area-zone-button').addClass('d-none');
                }
                if (!$('#choose-seat-for-me-button').hasClass('d-none')) {
                    $('#choose-seat-for-me-button').addClass('d-none');
                }
            });


            $(document).on("click", ".event-ticket-category-with-area-button", function () {
                $('.alert:not(.alert-static)').remove();
                if (!$(this).closest('.row').find('div.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                    $(this).closest('.row').find('div.event-ticket-category-with-area-button-wrapper').addClass('d-none');
                }
                eventTicketCategoryId = $(this).data("event-ticket-category-id");
                openAreaZone(eventTicketCategoryId);
            });

            function openAreaZone(eventTicketCategoryId) {
                $('.event-ticket-category-with-area').each(function () {
                        if (eventTicketCategoryId != $(this).data("event-ticket-category-id")) {
                            if (!$(this).closest('.row').hasClass("d-none")) {
                                $(this).closest('.row').addClass("d-none");
                            }
                        } else {
                            if ($(this).siblings('div.ticket-qty-container-wrapper').hasClass('d-none')) {
                                $(this).siblings('div.ticket-qty-container-wrapper').removeClass('d-none');
                            }
                            if ($(this).siblings('div.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                                $(this).siblings('div.event-ticket-category-with-area-button-wrapper').addClass('d-none');
                            }
                        }
                    }
                );
                getAreaZone(eventTicketCategoryId);
            }

            function getAreaZone(eventTicketCategoryId) {
                return;
                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/get_area_zone",
                    data: {
                        event_id: event_id,
                        event_subevent_id: eventSubeventId,
                        event_ticket_category_id: eventTicketCategoryId,
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $("#event-ticket-category-area-zone").remove();
                            $("#event-ticket-category-list").after(data.html);

                            if ($(".event-ticket-category-area-zone")[0]) {
                                let numberOfEventTicketCategoryAreaZones = 0;
                                $('.event-ticket-category-area-zone').each(function () {
                                    numberOfEventTicketCategoryAreaZones = numberOfEventTicketCategoryAreaZones + 1;
                                });
                                if (numberOfEventTicketCategoryAreaZones == 1 && !isMobile) {
                                    $('.event-ticket-category-area-zone').each(function () {
                                        areaZoneVersionId = $(this).find('button.event-ticket-category-area-zone-reservation-button').data("area-zone-version-id");
                                        let areaZoneStandingZone = $(this).find('button.event-ticket-category-area-zone-reservation-button').data("standing-zone");
                                        if (areaZoneStandingZone * 1 == 1) {
                                            isStandingZone = true;
                                        }
                                    });
                                    if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                        $('#event-ticket-category-area-zone').addClass('d-none');
                                    }
                                    if ($('#one-area-zone-next-button').hasClass('d-none')) {
                                        $('#one-area-zone-next-button').removeClass('d-none');
                                    }
                                } else {
                                    $("#one-area-zone-next-button").attr("data-area-zone-version-id", areaZoneVersionId);
                                }
                                if (!isMobile) {
                                    if (!$('#see-area-zone-button').hasClass('d-none')) {
                                        $('#see-area-zone-button').addClass('d-none');
                                    }
                                }
                                if (isMobile) {
                                    if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                        $('#event-ticket-category-area-zone').addClass('d-none');
                                    }
                                    if ($('#see-area-zone-button').hasClass('d-none')) {
                                        $('#see-area-zone-button').removeClass('d-none');
                                    }
                                }
                            }
                        }

                    })
                    .fail(function (data) {
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }
                    });
            }

            function showEventTicketCategory() {
                $('.event-ticket-category-with-area').each(function () {
                    if ($(this).closest('.row').hasClass("d-none")) {
                        $(this).closest('.row').removeClass("d-none");
                    }
                    if (!$(this).siblings('div.ticket-qty-container-wrapper').hasClass('d-none')) {
                        $(this).siblings('div.ticket-qty-container-wrapper').addClass('d-none');
                    }
                    if ($(this).siblings('div.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                        $(this).siblings('div.event-ticket-category-with-area-button-wrapper').removeClass('d-none');
                    }
                });
                $("#event-ticket-category-area-zone").remove();
                $('#div_order_buttons').find('.alert:not(.alert-static)').remove();
            }

            $(document).on("click", "#order-back-button", function () {
                if (!$('#order-back-button').hasClass('d-none')) {
                    $('#order-back-button').addClass('d-none');
                }
                showEventTicketCategory();
            });

            function startReservingTicketsWithAreaZone(areaZoneVersionId) {
                numberOfTickets = $('#event-ticket-category-' + eventTicketCategoryId).val() * 1;
                if (!(numberOfTickets > 0)) {
                    prependAlert('#div_order_buttons', 'Velg minst en billett', 'warning', null, '#order_register_form');
                    return false;
                }
                $('#div_order_buttons').find('.alert:not(.alert-static)').remove();
                reserveTicketsWithAreaZone(eventId, eventSubeventId, eventTicketCategoryId, areaZoneVersionId, numberOfTickets);
            }

            function startReservingTicketsWithAreaZoneV2() {
                let numberOfTicketsCount = 0;
                $('#event-ticket-category-list .event-ticket-category-list:not(.d-none) input').each(function () {
                    let inputTicketCount = $(this).val() * 1
                    if (!isNaN(inputTicketCount) && inputTicketCount > 0) {
                        numberOfTicketsCount += inputTicketCount;
                    }
                });

                if (!(numberOfTicketsCount > 0)) {
                    prependAlert('#div_order_buttons', 'Velg minst en billett', 'warning', null, '#order_register_form');
                    return false;
                } else {
                    numberOfTickets = numberOfTicketsCount;
                }

                $('#div_order_buttons').find('.alert:not(.alert-static)').remove();
                reserveTicketsWithAreaZoneV2();
            }

            $(document).on("click", "#one-area-zone-next-button", function () {
                chooseForMe = false;
                /*areaZoneVersionId = $(this).attr("data-area-zone-version-id");
                if ($(this).attr("data-standing-zone") * 1 == 1) {
                    isStandingZone = true;
                }*/
                startReservingTicketsWithAreaZoneV2();
            });

            function areaZoneShowTicketCategoriesAndButtons() {
                if (!isStandingZone) {
                    $('#choose-seat-for-me-button').removeClass('d-none');
                    $('#choose-seat-for-me-button-wrapper').removeClass('d-none');
                }
                $('#one-area-zone-next-button').removeClass('d-none');
                $('#event-ticket-category-area-zone').hide();
                $('#event-ticket-category-list .event-ticket-category-list').each(function () {
                    console.log('areaZoneId', areaZoneId)
                    if (!$(this).hasClass('area-zone-id-' + areaZoneId)) {
                        $(this).addClass('d-none');
                    }
                });
                if ($('.area-zone-selection-needed-hide-categories').length > 0) {
                    $('.area-zone-selection-needed-hide-categories').addClass('area-zone-selection-was-needed');
                    $('.area-zone-selection-needed-hide-categories').removeClass('area-zone-selection-needed-hide-categories');
                }
            }

            $(document).on("click", ".event-ticket-category-area-zone-selection-button", function () {
                selectZone($(this));
                let restrictionCodeFormButtonDiv = $('#restriction-code-form-button-div');
                if (restrictionCodeFormButtonDiv.length > 0 && restrictionCodeFormButtonDiv.hasClass('d-none')) {
                    restrictionCodeFormButtonDiv.removeClass('d-none');
                }
                if (periodeTicketAsGiftFormButtonDiv.length > 0 && periodeTicketAsGiftFormButtonDiv.hasClass('d-none')) {
                    periodeTicketAsGiftFormButtonDiv.removeClass('d-none');
                }
            });

            function selectZone(zoneBtn) {
                areaZoneId = zoneBtn.data("area-zone-id");
                if (zoneBtn.attr("data-standing-zone") * 1 == 1) {
                    isStandingZone = true;
                } else {
                    isStandingZone = false;
                }
                areaZoneVersionId = zoneBtn.data("area-zone-version-id");
                let areaZoneName = $('h2', zoneBtn.closest('.event-ticket-category-area-zone')).text();
                let selectedAreaZoneInfo = $('#selected-area-zone-info');
                selectedAreaZoneInfo.data('area_zone_id', areaZoneId);
                selectedAreaZoneInfo.data('area_zone_version_id', areaZoneVersionId);
                $('h3', selectedAreaZoneInfo).text(areaZoneName);
                selectedAreaZoneInfo.show();

                areaZoneShowTicketCategoriesAndButtons();
            }

            function reserveTicketsWithAreaZoneV2(seatIds = [], submitAfter = false) {
                showOrganizerAdminFreeOrder(false);
                let organizerAdminOrderFree = $('#organizer-admin-order-free');
                let formDataReservation = new FormData(document.getElementById('order_register_form'));
                formDataReservation.append("area_zone_id", areaZoneId);
                formDataReservation.append("area_zone_version_id", areaZoneVersionId);
                seatIds.forEach((item) => formDataReservation.append("seat_ids[]", item))
                let organizer_admin_order_free = (organizerAdminOrderFree.length > 0 && organizerAdminOrderFree.is(":checked")) ? 1 : 0;
                formDataReservation.append("organizer_admin_order_free", "" + organizer_admin_order_free);
                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/reservation_with_seat",
                    data: formDataReservation,
                    async: true,
                    cache: false,
                    processData: false,
                    contentType: false
                })
                    .done(function (data) {

                        if (data.expiration !== undefined && data.expiration !== null) {
                            if (submitAfter) {
                                $('#order_register_form').submit();
                            }

                            hideDuringReservation();

                            if (!$('#restriction-code-form').hasClass('d-none')) {
                                $('#restriction-code-form').addClass('d-none');
                            }
                            if (!$('#restriction-code-form-button-div').hasClass('d-none')) {
                                $('#restriction-code-form-button-div').addClass('d-none');
                            }
                            if (periodeTicketAsGiftForm.length > 0 && !periodeTicketAsGiftForm.hasClass('d-none')) {
                                periodeTicketAsGiftForm.addClass('d-none');
                            }
                            if (periodeTicketAsGiftFormButtonDiv.length > 0 && !periodeTicketAsGiftFormButtonDiv.hasClass('d-none')) {
                                periodeTicketAsGiftFormButtonDiv.addClass('d-none');
                            }
                            if (!$(eventTicketCategoryList).hasClass('d-none')) {
                                $(eventTicketCategoryList).addClass('d-none');
                            }
                            if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                $('#event-ticket-category-area-zone').addClass('d-none');
                            }
                            if ($('#order-cancel-button').hasClass('d-none')) {
                                $('#order-cancel-button').removeClass('d-none');
                            }
                            if ($('#order-cancel-button').css('display', 'none')) {
                                $('#order-cancel-button').css('display', 'inline-block');
                            }
                            if (!$('#order-back-button').hasClass('d-none')) {
                                $('#order-back-button').addClass('d-none');
                            }

                            if (!$('#order-next-button').hasClass('pos-order')) {
                                if ($('#order-next-button').hasClass('d-none')) {
                                    $('#order-next-button').removeClass('d-none');
                                }
                                if ($('#order-next-button').css('display', 'none')) {
                                    $('#order-next-button').css('display', 'inline-block');
                                }
                            } else {
                                if ($('#order-register-button').hasClass('d-none')) {
                                    $('#order-register-button').removeClass('d-none');
                                }
                                if (!$('#order-next-button').hasClass('d-none')) {
                                    $('#order-next-button').addClass('d-none');
                                }
                            }
                            if ($('#choose-seat-for-me-button-wrapper').length && !$('#choose-seat-for-me-button-wrapper').hasClass('d-none')) {
                                $('#choose-seat-for-me-button-wrapper').addClass('d-none');
                            }
                            if ($('#choose-seat-for-me-button').length && !$('#choose-seat-for-me-button').hasClass('d-none')) {
                                $('#choose-seat-for-me-button').addClass('d-none');
                            }

                            if (isStandingZone) {
                                openOrderForm();
                                return false;
                            }

                            if (seatIds.length > 0) {
                                openOrderForm();
                                return false;
                            }

                            if (chooseForMe) {
                                if ($("#seats").length) {
                                    $("#seats").empty();
                                }
                                let seatCoordinates = JSON.parse(data.seat_coordinates);
                                let maxSeatsPerRow = data.max_seat_per_row;
                                let maxRows = data.max_rows;
                                numberOfSeats = data.number_of_seats;
                                numberOfSeatsReserved = data.number_of_seats;
                                seatIdArray = [...data.reserved_seat_id_array];
                                reservedSeatIdArray = data.reserved_seat_id_array;
                                setSceneHeight(maxRows);
                                generateSeats(seatCoordinates, maxSeatsPerRow, maxRows);

                                if (data.area_zone_name !== undefined && data.area_zone_name !== null) {
                                    $('#area-zone-name').text(data.area_zone_name);
                                }

                                if ($('#order-next-button').hasClass('pos-order')) {
                                    $('#order_register_form').submit();
                                } else {
                                    openOrderForm();
                                }

                                return false;
                            }

                            if (data.seat_coordinates !== undefined && data.seat_coordinates !== null) {
                                if (!$('#event-ticket-categories').hasClass('area-zone-fixed-center')) {
                                    $('#event-ticket-categories').addClass('area-zone-fixed-center');
                                }
                                if ($('#scene-wrapper-order').hasClass('d-none')) {
                                    $('#scene-wrapper-order').removeClass('d-none');
                                }
                                if (!$('#one-area-zone-next-button').hasClass('d-none')) {
                                    $('#one-area-zone-next-button').addClass('d-none');
                                }
                                if ($("#seats").length) {
                                    $("#seats").empty();
                                }
                                let seatCoordinates = JSON.parse(data.seat_coordinates);
                                let maxSeatsPerRow = data.max_seat_per_row;
                                let maxRows = data.max_rows;
                                numberOfSeats = data.number_of_seats;
                                numberOfSeatsReserved = data.number_of_seats;
                                seatIdArray = [...data.reserved_seat_id_array];
                                reservedSeatIdArray = data.reserved_seat_id_array;
                                setSceneHeight(maxRows);
                                generateSeats(seatCoordinates, maxSeatsPerRow, maxRows);
                            }

                            if (data.discount !== undefined && data.discount !== null) {
                                discount = data.discount;
                            }

                            if (data.event_form_html !== undefined && data.event_form_html !== null) {
                                event_form_html = data.event_form_html;
                            }

                            if (data.area_zone_name !== undefined && data.area_zone_name !== null) {
                                $('#area-zone-name').text(data.area_zone_name);
                            }

                            if (!$('#order-next-button').hasClass('pos-order')) {
                                if ($('#order-next-button').hasClass('d-none')) {
                                    $('#order-next-button').removeClass('d-none');
                                }
                            } else {
                                if ($('#order-register-button').hasClass('d-none')) {
                                    $('#order-register-button').removeClass('d-none');
                                }
                                $('#order-register-button').css('display', 'inline-block');
                                if (!$('#order-next-button').hasClass('d-none')) {
                                    $('#order-next-button').addClass('d-none');
                                }
                            }

                            if (isMobile) {
                                openOrderForm();
                                return false;
                            }

                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }

                    });
            }

            function reserveTicketsWithAreaZone(eventId, eventSubeventId, eventTicketCategoryId, areaZoneVersionId, numberOfTickets, seatIds = []) {
                showOrganizerAdminFreeOrder(false);
                let organizerAdminOrderFree = $('#organizer-admin-order-free');
                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/reservation_with_seat",
                    data: {
                        event_id: event_id,
                        event_subevent_id: eventSubeventId,
                        event_ticket_category_id: eventTicketCategoryId,
                        area_zone_version_id: areaZoneVersionId,
                        number_of_tickets: numberOfTickets,
                        seat_ids: seatIds,
                        organizer_admin_order_free: (organizerAdminOrderFree.length > 0 && organizerAdminOrderFree.is(":checked")) ? 1 : 0
                    }
                })
                    .done(function (data) {

                        if (data.expiration !== undefined && data.expiration !== null) {
                            hideDuringReservation();

                            if (!$('#restriction-code-form').hasClass('d-none')) {
                                $('#restriction-code-form').addClass('d-none');
                            }
                            if (!$('#restriction-code-form-button-div').hasClass('d-none')) {
                                $('#restriction-code-form-button-div').addClass('d-none');
                            }
                            if (periodeTicketAsGiftForm.length > 0 && !periodeTicketAsGiftForm.hasClass('d-none')) {
                                periodeTicketAsGiftForm.addClass('d-none');
                            }
                            if (periodeTicketAsGiftFormButtonDiv.length > 0 && !periodeTicketAsGiftFormButtonDiv.hasClass('d-none')) {
                                periodeTicketAsGiftFormButtonDiv.addClass('d-none');
                            }
                            if (!$(eventTicketCategoryList).hasClass('d-none')) {
                                $(eventTicketCategoryList).addClass('d-none');
                            }
                            if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                $('#event-ticket-category-area-zone').addClass('d-none');
                            }
                            if ($('#order-cancel-button').hasClass('d-none')) {
                                $('#order-cancel-button').removeClass('d-none');
                            }
                            if ($('#order-cancel-button').css('display', 'none')) {
                                $('#order-cancel-button').css('display', 'inline-block');
                            }
                            if (!$('#order-back-button').hasClass('d-none')) {
                                $('#order-back-button').addClass('d-none');
                            }

                            if (!$('#order-next-button').hasClass('pos-order')) {
                                if ($('#order-next-button').hasClass('d-none')) {
                                    $('#order-next-button').removeClass('d-none');
                                }
                                if ($('#order-next-button').css('display', 'none')) {
                                    $('#order-next-button').css('display', 'inline-block');
                                }
                            } else {
                                if ($('#order-register-button').hasClass('d-none')) {
                                    $('#order-register-button').removeClass('d-none');
                                }
                                if (!$('#order-next-button').hasClass('d-none')) {
                                    $('#order-next-button').addClass('d-none');
                                }
                            }
                            if ($('#choose-seat-for-me-button').length && !$('#choose-seat-for-me-button').hasClass('d-none')) {
                                $('#choose-seat-for-me-button').addClass('d-none');
                            }

                            if (isStandingZone) {
                                openOrderForm();
                                return false;
                            }

                            if (seatIds.length > 0) {
                                openOrderForm();
                                return false;
                            }

                            if (chooseForMe) {
                                if ($("#seats").length) {
                                    $("#seats").empty();
                                }
                                let seatCoordinates = JSON.parse(data.seat_coordinates);
                                let maxSeatsPerRow = data.max_seat_per_row;
                                let maxRows = data.max_rows;
                                numberOfSeats = data.number_of_seats;
                                numberOfSeatsReserved = data.number_of_seats;
                                seatIdArray = [...data.reserved_seat_id_array];
                                reservedSeatIdArray = data.reserved_seat_id_array;
                                setSceneHeight(maxRows);
                                generateSeats(seatCoordinates, maxSeatsPerRow, maxRows);

                                if (data.area_zone_name !== undefined && data.area_zone_name !== null) {
                                    $('#area-zone-name').text(data.area_zone_name);
                                }

                                if ($('#order-next-button').hasClass('pos-order')) {
                                    $('#order_register_form').submit();
                                } else {
                                    openOrderForm();
                                }

                                return false;
                            }

                            if (data.seat_coordinates !== undefined && data.seat_coordinates !== null) {
                                if (!$('#event-ticket-categories').hasClass('area-zone-fixed-center')) {
                                    $('#event-ticket-categories').addClass('area-zone-fixed-center');
                                }
                                if ($('#scene-wrapper-order').hasClass('d-none')) {
                                    $('#scene-wrapper-order').removeClass('d-none');
                                }
                                if (!$('#one-area-zone-next-button').hasClass('d-none')) {
                                    $('#one-area-zone-next-button').addClass('d-none');
                                }
                                if ($("#seats").length) {
                                    $("#seats").empty();
                                }
                                let seatCoordinates = JSON.parse(data.seat_coordinates);
                                let maxSeatsPerRow = data.max_seat_per_row;
                                let maxRows = data.max_rows;
                                numberOfSeats = data.number_of_seats;
                                numberOfSeatsReserved = data.number_of_seats;
                                seatIdArray = [...data.reserved_seat_id_array];
                                reservedSeatIdArray = data.reserved_seat_id_array;
                                setSceneHeight(maxRows);
                                generateSeats(seatCoordinates, maxSeatsPerRow, maxRows);
                            }

                            if (data.discount !== undefined && data.discount !== null) {
                                discount = data.discount;
                            }

                            if (data.event_form_html !== undefined && data.event_form_html !== null) {
                                event_form_html = data.event_form_html;
                            }

                            if (data.area_zone_name !== undefined && data.area_zone_name !== null) {
                                $('#area-zone-name').text(data.area_zone_name);
                            }

                            if (!$('#order-next-button').hasClass('pos-order')) {
                                if ($('#order-next-button').hasClass('d-none')) {
                                    $('#order-next-button').removeClass('d-none');
                                }
                            } else {
                                if ($('#order-register-button').hasClass('d-none')) {
                                    $('#order-register-button').removeClass('d-none');
                                }
                                $('#order-register-button').css('display', 'inline-block');
                                if (!$('#order-next-button').hasClass('d-none')) {
                                    $('#order-next-button').addClass('d-none');
                                }
                            }

                            if (isMobile) {
                                openOrderForm();
                                return false;
                            }

                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }

                    });
            }

            function openOrderForm() {
                if ($('#selected-area-zone-info').length > 0) {
                    $('#selected-area-zone-info').hide();
                }
                if (!$('#scene-wrapper-order').hasClass('d-none')) {
                    $('#scene-wrapper-order').addClass('d-none');
                }
                if ($('#hide-order-form').hasClass('d-none')) {
                    $('#hide-order-form').removeClass('d-none');
                }
                if ($('#hide-order-form').css('display', 'none')) {
                    $('#hide-order-form').css('display', 'block');
                }
                if (!$('#see-area-zone-button').hasClass('d-none')) {
                    $('#see-area-zone-button').addClass('d-none');
                }

                //$('#number-of-tickets-for-order').text(numberOfTickets);
                if (discount !== undefined && discount !== null) {
                    addDiscountCookie(discount);
                    reservationOk(discount);
                } else {
                    reservationOk();
                }
                if (reservationCountDownInterval !== null) {
                    clearInterval(reservationCountDownInterval);
                    reservationCountDownInterval = null;
                    countDownId.text(expirationText + expirationTimeText);
                }

                reservationCountDownInterval = reservationCountDown(expirationTime, countDownId, expirationText);
                if (event_form_html !== undefined && event_form_html !== null) {
                    $('#event-form').html(event_form_html);
                }
                if ($('#order-cancel-button').hasClass('d-none')) {
                    $('#order-cancel-button').removeClass('d-none');
                }
                if ($('#order-register-button').hasClass('d-none')) {
                    $('#order-register-button').removeClass('d-none');
                }
                if ($('#one-area-zone-next-button').length && !$('#one-area-zone-next-button').hasClass('d-none')) {
                    $('#one-area-zone-next-button').addClass('d-none');
                }
                if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                    $('#event-ticket-category-area-zone').addClass('d-none');
                }
                if (isStandingZone && !$('#area-zone-seat-icon-wrapper').hasClass('d-none')) {
                    $('#area-zone-seat-icon-wrapper').addClass('d-none');
                }
                if (!isStandingZone && $('#area-zone-seat-icon-wrapper').hasClass('d-none')) {
                    $('#area-zone-seat-icon-wrapper').removeClass('d-none');
                }

            }

            function isArraysEqual(a1, a2) {
                return JSON.stringify(a1) == JSON.stringify(a2);
            }

            function removeContainersWithoutSelectedCategories() {
                $("input.container-category-input").each(function () {
                    let containerInput = $(this);
                    let containerEventTicketCategoryId = $(".container-category-select", containerInput.closest('.ticket-qty-container')).data("container-event-ticket-category-id");
                    let categorySelected = false;
                    $("input.container-sub-category-" + containerEventTicketCategoryId).each(function () {
                        if (!isNaN($(this).val()) && $(this).val() > 0) {
                            categorySelected = true;
                        }
                    });
                    if (!categorySelected) {
                        containerInput.val(0);
                    }
                });
            }

            $(document).on('click', '#order-register-button', function () {
                let isPos = ($('#show-event').hasClass('pos-event'));
                if (isPos && $('#scene-wrapper-order').length > 0 && $('#scene-wrapper-order').is(':visible')) {
                    if (Array.isArray(reservedSeatIdArray) && !isArraysEqual(seatIdArray, reservedSeatIdArray)) {
                        $.ajax({
                            type: 'GET',
                            url: "/ajax/order/cancel_reservation"
                        })
                            .done(function (data) {
                                if (reservationCountDownInterval !== null) {
                                    clearInterval(reservationCountDownInterval);
                                    reservationCountDownInterval = null;
                                    countDownId.text(expirationText + expirationTimeText);
                                }
                                removeDiscountCookie();
                                reserveTicketsWithAreaZoneV2(seatIdArray, true);
                            })
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
                return false;
            });


            $(nextButton).click(function (e) {

                e.preventDefault();

                if ($('#event-ticket-categories').hasClass('area-zone-fixed-center')) {
                    $('#event-ticket-categories').removeClass('area-zone-fixed-center');
                }

                if (Array.isArray(reservedSeatIdArray) && !isArraysEqual(seatIdArray, reservedSeatIdArray)) {
                    $.ajax({
                        type: 'GET',
                        url: "/ajax/order/cancel_reservation"
                    })
                        .done(function (data) {
                            if (reservationCountDownInterval !== null) {
                                clearInterval(reservationCountDownInterval);
                                reservationCountDownInterval = null;
                                countDownId.text(expirationText + expirationTimeText);
                            }
                            removeDiscountCookie();
                            reserveTicketsWithAreaZoneV2(seatIdArray);
                        })
                    return false;
                }

                if ($('#scene-wrapper-order').length && Array.isArray(reservedSeatIdArray) && isArraysEqual(seatIdArray, reservedSeatIdArray)) {
                    openOrderForm();
                    return false;
                }


                if ($('#reservation-alert').length !== 0) {
                    $('#reservation-alert').remove();
                }

                removeContainersWithoutSelectedCategories();

                let formDataReservation = new FormData(document.getElementById('order_register_form'));

                $.ajax({
                    type: 'GET',
                    url: "/ajax/order/cancel_reservation"
                })

                    .done(function () {
                        removeDiscountCookie();
                        startOrder(formDataReservation);
                    })

            });

            $(cancelButton).click(function (e) {

                e.preventDefault();

                if ($('#event-ticket-categories').hasClass('area-zone-fixed-center')) {
                    $('#event-ticket-categories').removeClass('area-zone-fixed-center');
                }

                $('.ticket-to-order').val(0);

                if ($('#reservation-alert').length !== 0) {
                    $('#reservation-alert').remove();
                }

                if ($('#scene-wrapper-order').length !== 0 && !$('#scene-wrapper-order').hasClass('d-none')) {
                    $('#scene-wrapper-order').addClass('d-none');
                }

                if ($('#choose-seat-for-me-button').length !== 0 && $('#choose-seat-for-me-button').hasClass('d-none')) {
                    $('#choose-seat-for-me-button').removeClass('d-none');
                }

                let orderContainerSummary = $('#order-container-summary');
                if (orderContainerSummary.length > 0) {
                    orderContainerSummary.remove();
                }

                //isStandingZone = false;

                orderButton.hide();
                cancelButton.hide();
                orderForm.hide();
                orderSummary.hide();
                orderTerms.hide();
                showOrganizerAdminFreeOrder(true);
                $(".container-category-list-wrapper").hide();

                showAfterCancellingReservation();

                if (!$('#see-area-zone-button').hasClass('d-none')) {
                    $('#see-area-zone-button').addClass('d-none');
                }

                if ($(eventTicketCategoryList).hasClass('d-none')) {
                    $('.remove-on-cancel', eventTicketCategoryList).remove();
                    $(eventTicketCategoryList).removeClass('d-none');
                }
                $(eventTicketCategoryList).show();

                if ($('#restriction-code-form-button-div').hasClass('d-none')) {
                    $('#restriction-code-form-button-div').removeClass('d-none');
                }
                if (!$('#restriction-code-form').hasClass('d-none') && !$('#restriction_code').val()) {
                    $('#restriction-code-form').addClass('d-none');
                }
                if (periodeTicketAsGiftForm.length > 0 && !periodeTicketAsGiftForm.hasClass('d-none') && !$('#period_ticket_as_gift').is(':checked')) {
                    periodeTicketAsGiftForm.addClass('d-none');
                }
                if (periodeTicketAsGiftFormButtonDiv.length > 0 && periodeTicketAsGiftFormButtonDiv.hasClass('d-none')) {
                    periodeTicketAsGiftFormButtonDiv.removeClass('d-none');
                }

                if ($('#clipping-card-code-form').length !== 0 && !$('#clipping-card-code-form').hasClass('d-none')) {
                    $('#clipping-card-code-form .alert-warning').remove();
                    $("#clipping_card_code").val(null);
                    $('#clipping-card-code-form').addClass('d-none');
                }
                if ($('#clipping-card-code-form-button-div').length !== 0 && !$('#clipping-card-code-form-button-div').hasClass('d-none')) {
                    $('#clipping-card-code-form-button-div').addClass('d-none');
                }

                if ($('#event-ticket-category-area-zone').length) {
                    let numberOfEventTicketCategory = 0;
                    $('.event-ticket-category-list').each(function () {
                        numberOfEventTicketCategory = numberOfEventTicketCategory + 1;
                    });
                    if (numberOfEventTicketCategory == 1 && 1 == 2) {
                        let numberOfEventTicketCategoryAreaZone = 0;
                        $('.event-ticket-category-area-zone').each(function () {
                            numberOfEventTicketCategoryAreaZone = numberOfEventTicketCategoryAreaZone + 1;
                        });
                        if (numberOfEventTicketCategoryAreaZone == 1) {
                            if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                $('#event-ticket-category-area-zone').addClass('d-none');
                            }
                            if ($('#one-area-zone-next-button').hasClass('d-none')) {
                                $('#one-area-zone-next-button').removeClass('d-none');
                            }
                        } else {
                            if ($('#event-ticket-category-area-zone').hasClass('d-none')) {
                                $('#event-ticket-category-area-zone').removeClass('d-none');
                            }
                            if (!$('#one-area-zone-next-button').hasClass('d-none')) {
                                $('#one-area-zone-next-button').addClass('d-none');
                            }
                        }
                    } else {
                        if ($('.event-ticket-category-list').hasClass('d-none')) {
                            $('.event-ticket-category-list').removeClass('d-none');
                        }
                        if ($('.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                            $('.event-ticket-category-with-area-button-wrapper').removeClass('d-none');
                        }
                        if ($('.event-ticket-category-area-zone').length > 0 || $('.event-ticket-category-area-zone .event-ticket-category-area-zone').length > 0) {
                            $('#selected-area-zone-info').show();
                        }
                        areaZoneShowTicketCategoriesAndButtons();

                        /*$('div.ticket-qty-container-wrapper').each(function () {
                            if (!$(this).hasClass('d-none')) {
                                $(this).addClass('d-none');
                            }
                        });*/
                    }
                    nextButton.hide();
                } else {
                    nextButton.show();
                }

                $.ajax({
                    type: 'GET',
                    url: "/ajax/order/cancel_reservation"
                })
                    .done(function (data) {

                        removeDiscountCookie();
                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#div_order_buttons', data.message, 'warning', null, '#order_register_form')
                        }

                        setTimeout(function () {
                            if ($('#area-zone-seat-icon-wrapper').length > 0 && $('.event-ticket-category-area-zone').length === 0) {
                                document.location.reload();
                            }
                        }, 1000);

                    })

            });

            $(document).on("click", "#show-order-summary-toggle-button", function () {

                if ($('#ul-order-summary').hasClass('d-none')) {
                    $('#ul-order-summary').removeClass('d-none');
                    $('#show-order-summary-toggle-button').addClass('show');
                } else {
                    $('#ul-order-summary').addClass('d-none');
                    $('#show-order-summary-toggle-button').removeClass('show');
                }

                if ($('#order-summary-title').hasClass('desktop-view')) {
                    $('#order-summary-title').removeClass('desktop-view');
                } else {
                    $('#order-summary-title').addClass('desktop-view');
                }

                if ($('#show-order-summary-toggle-button').hasClass('show')) {
                    $('#show-order-summary-toggle-button').html($('#show-order-summary-toggle-button').attr("data-order-summary-hide"));
                } else {
                    $('#show-order-summary-toggle-button').html($('#show-order-summary-toggle-button').attr("data-order-summary-show"));
                }

                return false;

            });

            $(document).on("click", "#period-ticket-as-gift-redeem-form-toggle", function () {
                let periodTicketAsGiftRedeemForm = $('#period-ticket-as-gift-redeem-form');
                if (periodTicketAsGiftRedeemForm.hasClass('d-none')) {
                    periodTicketAsGiftRedeemForm.removeClass('d-none');
                } else {
                    periodTicketAsGiftRedeemForm.addClass('d-none');
                }
                return false;
            });

            let periodTicketAsGiftRedeemAjaxLock = false;
            $(document).on('submit', '#period-ticket-as-gift-redeem-form', function (e) {
                e.preventDefault();

                if (!periodTicketAsGiftRedeemAjaxLock) {
                    periodTicketAsGiftRedeemAjaxLock = true;

                    let giftCode = $("#period-ticket-as-gift-redeem-form #period_ticket_gift_code").val();

                    if ($('#period-ticket-as-gift-redeem-form .alert').length !== 0) {
                        $('#period-ticket-as-gift-redeem-form .alert').remove();
                    }

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/event/redeem_gift_code",
                        data: {
                            organizer_id: organizer_id,
                            event_id: event_id,
                            gift_code: giftCode
                        }
                    })
                        .done(function (data) {
                            if (data.status !== undefined && data.status == 'OK') {
                                if (data.discount !== undefined && data.discount !== null) {
                                    addDiscountCookie(data.discount);
                                }
                                document.location.reload();
                            }
                            periodTicketAsGiftRedeemAjaxLock = false;
                        })
                        .fail(function (data) {
                            periodTicketAsGiftRedeemAjaxLock = false;
                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#period-ticket-as-gift-redeem-form', data.responseJSON.error_message, 'danger', null, '#period-ticket-as-gift-redeem-form')
                            }
                        });
                }
                return false;
            });

            $(document).on("click", "#period-ticket-as-gift-form-button", function () {
                if (periodeTicketAsGiftForm.hasClass('d-none')) {
                    periodeTicketAsGiftForm.removeClass('d-none');
                    $("#period_ticket_as_gift").prop("checked", true).change();
                } else {
                    periodeTicketAsGiftForm.addClass('d-none');
                    $("#period_ticket_as_gift").prop("checked", false).change();
                }
                return false;
            });

            $(document).on("click", "#restriction-code-form-button", function () {

                if ($('#restriction-code-form').hasClass('d-none')) {
                    $('#restriction-code-form').removeClass('d-none');
                } else {
                    $('#restriction-code-form').addClass('d-none');
                }

                return false;

            });


            $('#restriction_code').on('keypress', function (e) {
                if (e.which === 13) {
                    getEventTicketCategoryWithRestictionCode()
                }
            });


            $(document).on("click", "#event-ticket-restriction-code-button", function () {
                getEventTicketCategoryWithRestictionCode()

            });


            function getEventTicketCategoryWithRestictionCode() {

                let restriction_code = $("#restriction_code").val();
                let event_subevent_id = ($("input#subevent-id").length) ? $("input#subevent-id").val() : 0;
                let event_subevent_timeslot_id = ($("input#event-subevent-timeslot-id").length) ? $("input#event-subevent-timeslot-id").val() : 0;
                let periodTicketInput = $("#order_register_form #period_ticket");
                let includePeriodTickets = (periodTicketInput.length > 0 && periodTicketInput.val() == 1) ? 1 : 0;

                if ($('#message').length !== 0) {
                    $('#message').remove();
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/event/show",
                    data: {
                        organizer_id: organizer_id,
                        event_id: event_id,
                        event_subevent_id: event_subevent_id,
                        event_subevent_timeslot_id: event_subevent_timeslot_id,
                        restriction_code: restriction_code,
                        area_zone_id: areaZoneId,
                        include_period_tickets: includePeriodTickets
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#event-ticket-category-list").remove();

                            let selectedAreaZoneInfo = $('#selected-area-zone-info');
                            if (selectedAreaZoneInfo.length > 0 && !selectedAreaZoneInfo.hasClass('d-none') && selectedAreaZoneInfo.is(':visible')) {
                                selectedAreaZoneInfo.after(data.html);
                            } else {
                                $("#queue-count-down-div").after(data.html);
                            }

                            if (data.html.indexOf('input') !== -1) {
                                let areaZoneNextBtn = $('#one-area-zone-next-button');
                                if (!(areaZoneNextBtn.length > 0 && !areaZoneNextBtn.hasClass('d-none'))) {
                                    $('#div_order_buttons #order-next-button').removeClass('d-none');
                                }
                            }
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }

                    });
            }


            function reservationCountDown(expirationTime, countDownId, expirationText) {

                let remainingTime = expirationTime;
                let reservationExpired = $("#order-count-down-timer").attr('data-reservation-expired');

                if ($('.mobile-view').is(":visible")) {
                    $('html, body').animate({
                        scrollTop: $("#order_register_form").offset().top - 15
                    }, 100);
                }

                let x = setInterval(function () {

                    remainingTime = remainingTime - 1000;
                    let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = ('0' + Math.floor((remainingTime % (1000 * 60)) / 1000)).slice(-2);

                    countDownId.text(expirationText + " " + minutes + ":" + seconds);

                    if (remainingTime <= 0) {
                        clearInterval(x);
                        $.ajax({
                            type: 'GET',
                            url: "/ajax/order/cancel_reservation_timeout"
                        })

                            .done(function () {
                                $('.remove-on-cancel', eventTicketCategoryList).remove();
                                removeDiscountCookie();
                                startReservation();
                                prependAlert('#div_order_buttons', reservationExpired, 'warning', null, '#div_order_buttons');
                                $(".ticket-to-order").each(function () {
                                    $(this).val(0);
                                });
                                $(".container-category-list-wrapper").hide();

                                if ($('.area-zone-selection-was-needed').length > 0) {
                                    $('#selected-area-zone-info').show();
                                    if (!$('.area-zone-selection-was-needed').hasClass('area-zone-selection-needed-hide-categories')) {
                                        $('.area-zone-selection-was-needed').addClass('area-zone-selection-needed-hide-categories').removeClass('area-zone-selection-was-needed');
                                    }
                                }

                                if ($('.modal.show').length > 0) {
                                    $('.modal.show').modal('hide');
                                }

                                setTimeout(function () {
                                    if (($('.event-ticket-category-area-zone').length > 1) || ($('#area-zone-seat-icon-wrapper').length > 0 && $('.event-ticket-category-area-zone').length === 0) || ($('#scene-wrapper-order').length > 0 && !$('#scene-wrapper-order').hasClass('d-none'))) {
                                        document.location.reload();
                                    }
                                }, 1000);
                            })

                    }

                }, 1000);

                return x;

            }


            function reservationOk(discount = null) {

                let ticketsTotalPrice = 0;
                let ticketsTotalVat = 0;
                let ticketsToOrder = 0;
                let errorNoTicket = '';
                let tableOrderSummary = $("#table-order-summary");
                let hideFeeInCheckout = (tableOrderSummary.data('hide-fee-in-checkout') == 1);
                let discountSumIncVatTotal = 0;
                let discountSumVat = 0;

                let isEventClippingCard = $("#order_register_form").attr("data-event-clipping-card") === 'true';

                tableOrderSummary.empty();
                $("#ul-order-summary").empty();

                if ($('#ul-order-summary').length && !$('#ul-order-summary').hasClass('d-none')) {
                    $('#ul-order-summary').addClass('d-none');
                }
                if (!$('#order-summary-title').hasClass('desktop-view')) {
                    $('#order-summary-title').addClass('desktop-view');
                }

                $('.ticket-to-order:not(.container-sub-category)').each(function () {
                    ticketsToOrder += parseInt($(this).val());

                    if (parseInt($(this).val()) > 0) {

                        let organizerAdminOrderFree = $('#organizer-admin-order-free');
                        let organizerDiscountFree = ((organizerAdminOrderFree.length > 0 && organizerAdminOrderFree.is(":checked")) || countDownId.data('reservation-order-product-gift-id') != '');

                        let eventTicketCategoryId = $(this).attr("id");
                        eventTicketCategoryId = parseInt(eventTicketCategoryId.replace("event-ticket-category-", ""));
                        let eventTicketCategoryAmount = $(this).val();
                        let eventTicketCategoryVat = (organizerDiscountFree) ? 0 : parseFloat($(this).attr("data-price-vat"));
                        let eventTicketCategoryPriceIncVat = (organizerDiscountFree) ? 0 : parseFloat($(this).attr("data-price-inc-vat"));
                        let eventTicketCategoryFeeVat = (organizerDiscountFree) ? 0 : parseFloat($(this).attr("data-fee-vat"));
                        let eventTicketCategoryFeeIncVat = (organizerDiscountFree) ? 0 : parseFloat($(this).attr("data-fee-inc-vat"));
                        let eventTicketCategoryPriceWithFeeAndVatTotal = ((eventTicketCategoryPriceIncVat + eventTicketCategoryFeeIncVat) * eventTicketCategoryAmount).toFixed(2);

                        ticketsTotalPrice += (eventTicketCategoryPriceIncVat + eventTicketCategoryFeeIncVat) * eventTicketCategoryAmount;
                        ticketsTotalVat += (eventTicketCategoryVat + eventTicketCategoryFeeVat) * eventTicketCategoryAmount;

                        if (hideFeeInCheckout) {
                            eventTicketCategoryPriceIncVat = ((eventTicketCategoryPriceIncVat + eventTicketCategoryFeeIncVat) * eventTicketCategoryAmount);
                        }

                        eventTicketCategoryPriceIncVat = eventTicketCategoryPriceIncVat.toFixed(2);
                        eventTicketCategoryFeeIncVat = eventTicketCategoryFeeIncVat.toFixed(2);
                        eventTicketCategoryPriceIncVat = eventTicketCategoryPriceIncVat.replace(".", ",");
                        eventTicketCategoryFeeIncVat = eventTicketCategoryFeeIncVat.replace(".", ",");
                        eventTicketCategoryPriceWithFeeAndVatTotal = eventTicketCategoryPriceWithFeeAndVatTotal.replace(".", ",");

                        let containerNameWithSubCategories = $(this).attr("data-name");
                        if ($(this).hasClass('container-category-input')) {
                            let containerEventTicketCategoryId = $('.container-category-select', $(this).parent()).data('container-event-ticket-category-id');
                            let containerSubCategoryWrapper = $(".container-category-list-wrapper[data-container-event-ticket-category-id='" + containerEventTicketCategoryId + "']");
                            containerNameWithSubCategories = containerNameWithSubCategories + ' (';
                            $('.container-sub-category', containerSubCategoryWrapper).each(function () {
                                if (parseInt($(this).val()) > 0) {
                                    containerNameWithSubCategories = containerNameWithSubCategories + $(this).val() + 'x ' + $(this).attr("data-name") + ', ';
                                }
                            });
                            containerNameWithSubCategories = containerNameWithSubCategories.substring(0, containerNameWithSubCategories.length - 2) + ')';
                        }

                        let hideFeeInCheckoutClass = (hideFeeInCheckout) ? ' d-none' : '';

                        $('#table-order-summary').append($('<tr>')
                            .append($('<td class="text-left">').append(containerNameWithSubCategories))
                            .append($('<td class="text-center">').append($(this).val()))
                            .append($('<td class="text-right">').append("kr " + eventTicketCategoryPriceIncVat))
                            .append($('<td class="text-right' + hideFeeInCheckoutClass + '">').append("kr " + eventTicketCategoryFeeIncVat))
                            .append($('<td class="text-right">').append("kr " + eventTicketCategoryPriceWithFeeAndVatTotal))
                        );

                        $('#ul-order-summary').append($('<ul class="list-group mb-4">')
                            .append($('<li class="list-group-item">').append("<strong>" + $(this).attr("data-name") + "</strong>"))
                            .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-quantity') + ': ' + $(this).val()))
                            .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-price') + ': ' + "kr " + eventTicketCategoryPriceIncVat))
                            .append($('<li class="list-group-item' + hideFeeInCheckoutClass + '">').append($("#ul-order-summary").attr('data-fee') + ': ' + "kr " + eventTicketCategoryFeeIncVat))
                            .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-price-total') + ': ' + "kr " + eventTicketCategoryPriceWithFeeAndVatTotal))
                        );

                        if (discount !== undefined && discount !== null && discount != '') {

                            let discountPercent = 0;
                            let discountSumIncVatText = 0;
                            let discountSumIncVat = 0;
                            let discountSumIncVatForEventTicketCategory = 0;

                            let eventTicketCategoryDiscount;
                            if (discount[eventTicketCategoryId] !== undefined && discount[eventTicketCategoryId] !== null && discount[eventTicketCategoryId] != '') {
                                eventTicketCategoryDiscount = JSON.parse(discount[eventTicketCategoryId]);
                            }

                            if (eventTicketCategoryDiscount !== undefined && eventTicketCategoryDiscount !== null && eventTicketCategoryDiscount != '') {
                                discountPercent = eventTicketCategoryDiscount.discount_percent;
                                discountSumIncVatText = eventTicketCategoryDiscount.discount_sum_inc_vat;
                                discountSumIncVat = discountSumIncVatText.replace(",", ".");
                                discountSumIncVat = parseFloat(discountSumIncVat.replace(" ", ""));
                                discountSumIncVatForEventTicketCategory = discountSumIncVat * eventTicketCategoryAmount;
                                discountSumIncVatForEventTicketCategory = discountSumIncVatForEventTicketCategory.toFixed(2);
                                discountSumIncVatForEventTicketCategory = discountSumIncVatForEventTicketCategory.replace(".", ",");
                                discountSumIncVatTotal += parseFloat(discountSumIncVatForEventTicketCategory);
                                discountSumVat += parseFloat(eventTicketCategoryDiscount.discount_sum_vat) * eventTicketCategoryAmount;

                                $('#table-order-summary').append($('<tr>')
                                    .append($('<td class="text-left" style="border-top:0px">').append("Rabatt " + discountPercent + "%"))
                                    .append($('<td class="text-center" style="border-top:0px">').append($(this).val()))
                                    .append($('<td class="text-right" style="border-top:0px">').append("kr " + discountSumIncVatText))
                                    .append($('<td class="text-right' + hideFeeInCheckoutClass + '" style="border-top:0px">').append(" "))
                                    .append($('<td class="text-right" style="border-top:0px">').append("- kr " + discountSumIncVatForEventTicketCategory))
                                );

                                $('#ul-order-summary').append($('<ul class="list-group mb-4">')
                                    .append($('<li class="list-group-item">').append("<strong>" + discountPercent + "% rabatt for " + $(this).attr("data-name") + "</strong>"))
                                    .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-quantity') + ': ' + $(this).val()))
                                    .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-price') + ': ' + "kr " + discountSumIncVatText))
                                    .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-price-total') + ': ' + "- kr " + discountSumIncVatForEventTicketCategory))
                                );

                            }

                        }
                    }
                });

                if (ticketsToOrder > 0) {

                    /*if (ticketsTotalPrice === 0 && !$('#phone-number').hasClass('d-none')) {
                        $('#phone-number').addClass('d-none');
                        $("#phone-number input[name='phone_number']").prop('required', false);
                    }*/

                    if (ticketsTotalPrice > 0 && $('#phone-number').hasClass('d-none') && !$('#show-event').hasClass('pos-event')) {
                        $('#phone-number').removeClass('d-none');
                        if ($('#phone-number').hasClass('should-be-required')) {
                            $("#phone-number input[name='phone_number']").prop('required', true);
                        }
                    }

                    $(".alert:not(.alert-static)", $("#div_order_buttons")).remove();

                    orderButton.show();
                    cancelButton.show();
                    nextButton.hide();
                    $(eventTicketCategoryList).hide();
                    orderForm.show();
                    orderSummary.show();
                    orderTerms.show();

                    if (!$('#restriction-code-form').hasClass('d-none')) {
                        $('#restriction-code-form').addClass('d-none');
                    }
                    if (!$('#restriction-code-form-button-div').hasClass('d-none')) {
                        $('#restriction-code-form-button-div').addClass('d-none');
                    }
                    if (periodeTicketAsGiftForm.length > 0 && !periodeTicketAsGiftForm.hasClass('d-none')) {
                        periodeTicketAsGiftForm.addClass('d-none');
                    }
                    if (periodeTicketAsGiftFormButtonDiv.length > 0 && !periodeTicketAsGiftFormButtonDiv.hasClass('d-none')) {
                        periodeTicketAsGiftFormButtonDiv.addClass('d-none');
                    }

                    ticketsTotalPrice = ticketsTotalPrice - discountSumIncVatTotal;
                    ticketsTotalPrice = ticketsTotalPrice.toFixed(2);
                    ticketsTotalVat = ticketsTotalVat - discountSumVat;
                    ticketsTotalVat = ticketsTotalVat.toFixed(2);
                    ticketsTotalPrice = ticketsTotalPrice.replace(".", ",");
                    ticketsTotalVat = ticketsTotalVat.replace(".", ",");

                    let sum = tableOrderSummary.attr('data-sum');
                    let data_of_with_vat = tableOrderSummary.attr('data-of-with-vat');

                    tableOrderSummary.append($('<tr>')
                        .append($('<td>').append('<strong>' + sum + '</strong>'))
                        .append($('<td colspan="4"  class="text-right">').append('<strong>kr ' + ticketsTotalPrice + '</strong>'))
                    );

                    if (!isEventClippingCard) {
                        tableOrderSummary.append($('<tr>')
                            .append($('<td colspan="5" class="text-right">').append(data_of_with_vat + ' kr ' + ticketsTotalVat))
                        );
                    }

                    if (!isEventClippingCard) {
                        $('#ul-order-summary').append($('<ul class="list-group mb-4">')
                            .append($('<li class="list-group-item">').append("<strong>" + $("#ul-order-summary").attr('data-sum') + " kr: " + ticketsTotalPrice + "</strong>"))
                            .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-of-with-vat') + " kr: " + ticketsTotalVat))
                        );
                    }
                    if (isEventClippingCard) {
                        $('#ul-order-summary').append($('<ul class="list-group mb-4">')
                            .append($('<li class="list-group-item">').append("<strong>" + $("#ul-order-summary").attr('data-sum') + " kr: " + ticketsTotalPrice + "</strong>"))
                        );
                    }


                } else {
                    errorNoTicket = orderForm.attr("data-error-no-ticket");
                    prependAlert('#div_order_buttons', errorNoTicket, 'danger', null, '#div_order_buttons');
                }

            }


            function startReservation() {
                removeDiscountCookie();
                orderButton.hide();
                cancelButton.hide();
                nextButton.show();
                orderForm.hide();
                hideOrderForm.hide();
                orderSummary.hide();
                orderTerms.hide();

                showAfterCancellingReservation();

                if ($(eventTicketCategoryList).hasClass('d-none')) {
                    $(eventTicketCategoryList).removeClass('d-none');
                }
                $(eventTicketCategoryList).show();
                if ($('#event-ticket-category-area-zone').length && $('#event-ticket-category-area-zone').data('number-of-zones') > 1 && $('#event-ticket-category-area-zone').hasClass('d-none')) {
                    $('#event-ticket-category-area-zone').removeClass('d-none');
                }
                if ($('#event-ticket-category-area-zone').length && $('#event-ticket-category-area-zone').data('number-of-zones') == 1) {
                    let elementToGetDataFrom = ($('.event-ticket-category-area-zone-selection-button:first').length > 0) ? $('.event-ticket-category-area-zone-selection-button:first') : $('.event-subevent-list-sold-out:first');
                    selectZone(elementToGetDataFrom);
                }

                if ($('#clipping-card-code-form').length !== 0 && !$('#clipping-card-code-form').hasClass('d-none')) {
                    $('#clipping-card-code-form .alert-warning').remove();
                    $("#clipping_card_code").val(null);
                    $('#clipping-card-code-form').addClass('d-none');
                }
                if ($('#clipping-card-code-form-button-div').length !== 0 && !$('#clipping-card-code-form-button-div').hasClass('d-none')) {
                    $('#clipping-card-code-form-button-div').addClass('d-none');
                }

            }


            function continueReservation() {

                let selectedAreaZoneInfo = $('#selected-area-zone-info');
                if (selectedAreaZoneInfo.length > 0) {
                    if (!isNaN(selectedAreaZoneInfo.data('area_zone_version_id')) && !isNaN(selectedAreaZoneInfo.data('area_zone_id'))) {
                        areaZoneId = selectedAreaZoneInfo.data('area_zone_id');
                        areaZoneVersionId = selectedAreaZoneInfo.data('area_zone_version_id');
                    }
                    if (!isNaN(selectedAreaZoneInfo.data('is_standing_zone'))) {
                        isStandingZone = (selectedAreaZoneInfo.data('is_standing_zone') == 1);
                    }

                    if (isStandingZone && !$('#area-zone-seat-icon-wrapper').hasClass('d-none')) {
                        $('#area-zone-seat-icon-wrapper').addClass('d-none');
                    }
                }

                let expirationTime = parseInt(countDownId.attr("data-reservation-remaining-time")) * 1000;

                if (orderButton.hasClass('d-none')) {
                    orderButton.removeClass('d-none');
                }

                if (cancelButton.hasClass('d-none')) {
                    cancelButton.removeClass('d-none');
                }

                if (hideOrderForm.hasClass('d-none')) {
                    hideOrderForm.removeClass('d-none');
                }

                showOrganizerAdminFreeOrder(false);

                if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                    $('#event-ticket-category-area-zone').addClass('d-none');
                }

                if (!$('#one-area-zone-next-button').hasClass('d-none')) {
                    $('#one-area-zone-next-button').addClass('d-none');
                }

                hideOrderForm.show();
                let discountCookie = getCookie('discount');
                if (discountCookie !== undefined && discountCookie !== null && discountCookie != '') {
                    discountCookie = JSON.parse(discountCookie);
                    reservationOk(discountCookie);
                } else {
                    reservationOk();
                }
                reservationCountDownInterval = reservationCountDown(expirationTime, countDownId, expirationText);

                activateSelect2();

            }


            function startOrder(formDataReservation) {
                if (orderButton.hasClass('d-none')) {
                    orderButton.removeClass('d-none');
                }

                if (cancelButton.hasClass('d-none')) {
                    cancelButton.removeClass('d-none');
                }

                if (hideOrderForm.hasClass('d-none')) {
                    hideOrderForm.removeClass('d-none');
                }

                if ($('#reservation-alert-box').length !== 0) {
                    $('#reservation-alert-box').remove();
                }

                showOrganizerAdminFreeOrder(false);

                if (reservationCountDownInterval !== null) {
                    clearInterval(reservationCountDownInterval);
                    reservationCountDownInterval = null;
                    countDownId.text(expirationText + expirationTimeText);
                }

                hideOrderForm.show();

                activateSelect2();

                if (!reservation_ajax_lock) {

                    reservation_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/order/reservation",
                        data: formDataReservation,
                        async: true,
                        cache: false,
                        processData: false,
                        contentType: false
                    })
                        .done(function (data) {

                            if (data.expiration !== undefined && data.expiration !== null) {
                                hideDuringReservation();

                                if (!$('#restriction-code-form').hasClass('d-none')) {
                                    $('#restriction-code-form').addClass('d-none');
                                }
                                if (!$('#restriction-code-form-button-div').hasClass('d-none')) {
                                    $('#restriction-code-form-button-div').addClass('d-none');
                                }
                                if (periodeTicketAsGiftForm.length > 0 && !periodeTicketAsGiftForm.hasClass('d-none')) {
                                    periodeTicketAsGiftForm.addClass('d-none');
                                }
                                if (periodeTicketAsGiftFormButtonDiv.length > 0 && !periodeTicketAsGiftFormButtonDiv.hasClass('d-none')) {
                                    periodeTicketAsGiftFormButtonDiv.addClass('d-none');
                                }
                                if (!$(eventTicketCategoryList).hasClass('d-none')) {
                                    $(eventTicketCategoryList).addClass('d-none');
                                }
                                if (data.discount !== undefined && data.discount !== null) {
                                    addDiscountCookie(data.discount);
                                    reservationOk(data.discount);
                                } else {
                                    reservationOk();
                                }

                                let submitOrderRegisterForm = false;
                                if ($('#order-next-button').hasClass('pos-order') && !($('#one-area-zone-next-button').length > 0)) {
                                    if (!$('#order-next-button').is(':disabled')) {
                                        submitOrderRegisterForm = true;
                                    }
                                }

                                if (!submitOrderRegisterForm && data.refresh_page !== undefined && data.refresh_page == 1) {
                                    document.location.reload();
                                }

                                reservationCountDownInterval = reservationCountDown(expirationTime, countDownId, expirationText);

                                if (data.event_form_html !== undefined && data.event_form_html !== null) {
                                    $('#event-form').html(data.event_form_html);
                                }

                                if (data.clipping_card !== undefined && data.clipping_card !== null) {
                                    $('#clipping-card-code-form-button-div').removeClass('d-none');
                                } else {
                                    if (!$('#clipping-card-code-form-button-div').hasClass('d-none')) {
                                        $('#clipping-card-code-form-button-div').addClass('d-none');
                                    }
                                }

                                if (submitOrderRegisterForm) {
                                    $('#order-next-button').prop("disabled", true);
                                    $('#order_register_form').submit();
                                }
                            }

                        })
                        .fail(function (data) {

                            reservation_ajax_lock = false;

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                            }

                        })
                        .always(function (data) {
                            if ($('#order-next-button').hasClass('pos-order')) {
                                if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                    reservation_ajax_lock = false;
                                }
                            } else {
                                reservation_ajax_lock = false;
                            }
                        });

                }
            }

            function removeDiscountCookie() {
                let daysForCookieToExpire = 0;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "discount = ;expires=" + cookieExpires + "; path=/; SameSite=strict";
            }

            function addDiscountCookie(discount) {
                let daysForCookieToExpire = 1;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "discount = " + JSON.stringify(discount) + ";expires=" + cookieExpires + "; path=/; SameSite=strict";
            }

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }

            $('div.event-form-question-wrapper select').select2({
                theme: 'bootstrap4',
            });

            function setSceneHeight(maxRows) {
                let selectedSeatsWrapperHeight = $('#scene-selected-seats').outerHeight();
                if (thisIsMobile) { //  && !$('#show-event').hasClass('pos-event')
                    let svgHeight = $("#scene-wrapper-order #seats svg").outerHeight();
                    console.log('svgHeight', 16 + svgHeight);
                    if (isNaN(svgHeight) || svgHeight === null || svgHeight === 0) {
                        if ($('body').height() > $('body').width()) {
                            svgHeight = $('body').height() * 0.4;
                        } else {
                            svgHeight = $('body').height() * 0.60;
                        }
                    }
                    $("#scene-wrapper-order #seats").css("height", 16 + svgHeight);
                    let sceneWrapperHeight = svgHeight + 16 + $("#scene-wrapper-order #area-zone-name").outerHeight() + $("#scene-wrapper-order #scene").outerHeight() + selectedSeatsWrapperHeight;
                    //$("#scene-wrapper-order").css("height", 55 + sceneWrapperHeight);
                    $("#scene-wrapper-order").css("height", "100%");
                    console.log('svgHeight2', 15 + sceneWrapperHeight);
                } else {
                    let isPos = ($('#show-event').hasClass('pos-event'));
                    let extraHeightForPos = (isPos) ? -50 : 0;
                    $("#scene-wrapper-order").css("height", extraHeightForPos + 190 + (30 * maxRows));
                    /*if (isPos) {
                        $("#scene-wrapper-order #seats").css("height", "100%");
                    } else {*/
                    $("#scene-wrapper-order #seats").css("height", 60 + (30 * maxRows));
                    //}
                }
            }

            function generateSeats(seatCoordinates, maxSeatsPerRow = 0, maxRows = 0) {
                $('#scene-wrapper-order').data('max-rows', maxRows);

                let svgContainer;

                if (thisIsMobile) {
                    svgContainer = d3.select("#seats").append("svg").attr("width", "100%").attr("viewBox", "-10 10 " + Math.max(((maxSeatsPerRow * 30) + 10), 0) + " " + Math.max(((maxRows * 30) + 30), 0)).attr("preserveAspectRatio", "xMidYMid meet").attr("class", "generated-seats").attr("id", "generated-seats-svg");
                } else {
                    svgContainer = d3.select("#seats").append("svg").attr("width", Math.max(((maxSeatsPerRow * 30) - 10), 0) + "px").attr("height", "100%").attr("class", "generated-seats").attr("id", "generated-seats-svg");
                }

                let circles = svgContainer.selectAll("circle").data(seatCoordinates).enter().append("circle");
                $('#scene-selected-seats ul').html('');

                let circleAttributes = circles.attr("cx", function (d) {
                    return d.coordinate_x;
                }).attr("cy", function (d) {
                    return d.coordinate_y;
                }).attr("r", function (d) {
                    return d.radius;
                }).style("fill", function (d) {
                    return d.color;
                }).attr("id", function (d) {
                    return d.id;
                }).attr("rating", function (d) {
                    return d.rating;
                }).attr("status", function (d) {
                    return d.status;
                }).attr("reserved", function (d) {
                    if (d.reserved == 1) {
                        $('#scene-selected-seats ul').append('<li class="list-group-item" data-seat-id="' + d.id + '">' + d.row_and_seat_text + '</li>');
                    }
                    return d.reserved;
                }).attr("row_info", function (d) {
                    return d.row_info;
                }).attr("seat_info", function (d) {
                    return d.seat_info;
                }).attr("class", function (d) {
                    return d.class;
                }).append("title").text(function (d) {
                    let titleElement = document.createElement('title');
                    titleElement.text = d.row_and_seat_text;
                    return d.row_and_seat_text;
                });
                setSceneHeight(maxRows);

            }

            function removeValueFromArray(array, value) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i] === value) {
                        array.splice(i, 1);
                    }
                }
                return array;
            }

            $(document).on("click", ".generated-seat", function () {
                if ($(this).attr('status') == 0) {
                    return false;
                }
                let color, reserved;
                if ($(this).attr('reserved') == 1) {
                    $(".alert:not(.alert-static)", $("#order_register_form")).remove();
                    if (numberOfSeatsReserved > 0) {
                        numberOfSeatsReserved = numberOfSeatsReserved - 1;
                    }
                    reserved = 0;
                    color = generateColor(reserved);
                    $(this).attr("reserved", reserved);
                    $(this).attr("style", "fill:" + color);
                    console.log(seatIdArray);
                    seatIdArray = removeValueFromArray(seatIdArray, $(this).attr('id') * 1);
                    console.log(seatIdArray);
                    let selectedSeatInfoBox = $("#scene-selected-seats ul li[data-seat-id='" + $(this).attr('id') + "']");
                    if (selectedSeatInfoBox.length > 0) {
                        selectedSeatInfoBox.remove();
                    }
                } else if ($(this).attr('reserved') == 0) {
                    if (numberOfSeatsReserved < numberOfSeats) {
                        numberOfSeatsReserved = numberOfSeatsReserved + 1;
                    } else {
                        let removeSeatMessage = $('#div_order_buttons').attr('data-remove-seat-message');
                        prependAlert('#div_order_buttons', removeSeatMessage, 'warning', null, '#order_register_form');
                        return false;
                    }
                    reserved = 1;
                    color = generateColor(reserved);
                    $(this).attr("reserved", reserved);
                    $(this).attr("style", "fill:" + color);
                    console.log(seatIdArray);
                    seatIdArray.push($(this).attr('id') * 1);
                    console.log(seatIdArray);
                    $('#scene-selected-seats ul').append('<li class="list-group-item" data-seat-id="' + $(this).attr('id') + '">' + $('title', $(this)).text() + '</li>');
                }

            });

            function generateColor(status) {
                let color;
                switch (status) {
                    case 0:
                        color = "#28a745";
                        break;
                    case 1:
                        color = "#007bff";
                        break;
                    default:
                        color = "#28a745";
                }
                return color;
            }

            $(document).on("click", "#area-zone-seat-link, #area-zone-seat-icon", function () {
                if ($('.generated-seats').length == 0) {
                    let get_area_zone_seats_from_session_lock = false;
                    if (!get_area_zone_seats_from_session_lock) {
                        get_area_zone_seats_from_session_lock = true;
                        $.ajax({
                            type: 'POST',
                            url: "/ajax/order/get_area_zone_seats_from_session",
                            data: {}
                        })
                            .done(function (data) {
                                if (data.area_zone_seats !== undefined && data.area_zone_seats !== null) {
                                    data = data.area_zone_seats;
                                    let seatCoordinates = JSON.parse(data.seat_coordinates);
                                    let maxSeatsPerRow = data.max_seat_per_row;
                                    let maxRows = data.max_rows;
                                    numberOfSeats = data.number_of_seats;
                                    numberOfSeatsReserved = data.number_of_seats;
                                    seatIdArray = [...data.reserved_seat_id_array];
                                    reservedSeatIdArray = data.reserved_seat_id_array;
                                    numberOfTickets = data.number_of_tickets;
                                    areaZoneVersionId = data.area_zone_version_id;
                                    setSceneHeight(maxRows);
                                    generateSeats(seatCoordinates, maxSeatsPerRow, maxRows);

                                    if (data.area_zone_name !== undefined && data.area_zone_name !== null) {
                                        $('#area-zone-name').text(data.area_zone_name);
                                    }
                                }
                                get_area_zone_seats_from_session_lock = false;
                            })
                            .fail(function (data) {
                                get_area_zone_seats_from_session_lock = false;
                                if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                    prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                                }
                            });
                    }
                } else if ($('#scene-wrapper-order #seats').length > 0 && $('#scene-wrapper-order #seats').height() < 30) {
                    setSceneHeight($('#scene-wrapper-order').data('max-rows'));
                }

                reopenAreaZone();
            });

            function reopenAreaZone() {
                if (!$('#event-ticket-categories').hasClass('area-zone-fixed-center')) {
                    $('#event-ticket-categories').addClass('area-zone-fixed-center');
                }
                if ($('#scene-wrapper-order').hasClass('d-none')) {
                    $('#scene-wrapper-order').removeClass('d-none');
                }
                if (!$('#hide-order-form').hasClass('d-none')) {
                    $('#hide-order-form').addClass('d-none');
                }
                if (!$('#order-cancel-button').hasClass('d-none')) {
                    $('#order-cancel-button').addClass('d-none');
                }
                if (!$('#order-register-button').hasClass('d-none')) {
                    $('#order-register-button').addClass('d-none');
                }
                if ($('#order-next-button').hasClass('d-none')) {
                    $('#order-next-button').removeClass('d-none');
                }
                if ($('#order-next-button').css('display', 'none')) {
                    $('#order-next-button').css('display', 'inline-block');
                }
            }

            function showOrganizerAdminFreeOrder(show = true) {
                let organizerFreeOrderContainer = $('#organizer-admin-order-free-container');
                if (!(organizerFreeOrderContainer.length > 0)) {
                    return;
                }
                if (show === true) {
                    if (organizerFreeOrderContainer.hasClass('d-none')) {
                        organizerFreeOrderContainer.removeClass('d-none');
                    }
                } else if (show === false) {
                    if (!organizerFreeOrderContainer.hasClass('d-none')) {
                        organizerFreeOrderContainer.addClass('d-none');
                    }
                }
            }

        }

    }
);

