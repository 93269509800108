$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");

        if (p == 'organizer_event' && (a == 'new' || a == 'update')) {

            $(document).on("click", "#event_entry_point_register_button", function () {
                let eventEntryPointName = $('#event_entry_point_name').val();
                if (eventEntryPointName == '') {
                    let errorMessage = $('#event_entry_point_register_button').attr("data-empty-error-message");
                    alert(errorMessage);
                    return false;
                }
                let numberOfEventEntryPoints = $("[data-event-entry-point-id]").length;
                numberOfEventEntryPoints = numberOfEventEntryPoints + 1;
                let eventEntryPointInput = $("<input type='text' value='" + eventEntryPointName + "' class='d-none' />")
                    .attr("data-event-entry-point-id", numberOfEventEntryPoints)
                    .attr("name", "event_entry_point[new-" + numberOfEventEntryPoints + "][name]");
                $('#event-entry-points').append(eventEntryPointInput);
                let eventEntryPointDiv = $('<div class="col-md-12 mb-2 pl-2" id="event-entry-point-id-' + numberOfEventEntryPoints + '">' +
                    '<span class="material-icons mr-2 delete-event-entry-point" data-event-entry-point-delete-id="' + numberOfEventEntryPoints + '">delete</span>' +
                    eventEntryPointName +
                    '</div>');
                $('#event-entry-points').append(eventEntryPointDiv);
                $('#event_entry_point_name').val('');
            });


            $(document).on("click", ".delete-event-entry-point", function () {
                let eventEntryPointId = $(this).attr('data-event-entry-point-delete-id');
                $("#event-entry-point-id-" + eventEntryPointId).remove();
                $("#event-entry-points input[data-event-entry-point-id=" + eventEntryPointId + "]").remove();
            });


        }

    });