$(document).ready(
    function () {
        let p = $("body").attr("p");
        if (p == 'organizer_event_sales_area_zones') {
            let isMobile = false;
            let thisIsMobile = false
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
                thisIsMobile = true;
            }

            function getAreaZoneSeats() {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_sales_area_zones/show",
                    data: {
                        event_id: $("body").attr("event_id")
                    }
                })
                    .done(function (data) {
                        if (data !== undefined && data !== null && data.event_area_zones !== undefined) {
                            $.each(JSON.parse(data.event_area_zones), function (i, item) {
                                if (item.area_zone_id !== undefined) {
                                    let areaZoneId = (item.area_zone_id);
                                    let parentElementId = '.scene-wrapper-row[data-area-zone-id="' + areaZoneId + '"]';
                                    let parentElement = $(parentElementId);
                                    let standingZone = (item.standing_zone !== undefined && item.standing_zone == 1);

                                    if (standingZone) {
                                        let ticketsSold = (item.tickets_sold !== undefined) ? item.tickets_sold : 0;
                                        let ticketsReserved = (item.tickets_reserved !== undefined) ? item.tickets_reserved : 0;
                                        let peopleLimit = (item.people_limit !== undefined) ? item.people_limit : 0;
                                        let ticketsRemaining = peopleLimit - ticketsReserved - ticketsSold;

                                        $('.scene-wrapper', parentElement).css('height', 'auto');
                                        $('.seats', parentElement).css('height', 'auto');
                                        $('.scene', parentElement).remove();

                                        let summary = $('.event-area-zone-summary', parentElement);
                                        $('.summary-reserved', summary).text(ticketsReserved);
                                        $('.summary-sold', summary).text(ticketsSold);
                                        $('.summary-remaining', summary).text(ticketsRemaining);
                                        $('.summary-total', summary).text(peopleLimit);
                                        summary.removeClass('d-none');
                                    } else if (item.max_rows !== undefined && item.max_seat_per_row !== undefined && item.seat_coordinates !== undefined) {
                                        setSceneHeight(parentElement, item.max_rows);
                                        generateSeats(parentElementId, parentElement, item.seat_coordinates, item.max_seat_per_row, item.max_rows);
                                        updateSeatStatusColor();
                                    }
                                }
                            });
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    });
            }

            getAreaZoneSeats();

            function updateSeatStatusColor() {
                let seats = $(".scene-wrapper circle");
                $.each(seats, function () {
                    if ($(this).attr("reserved_by_others") == 1) {
                        $(this).attr("style", "fill:#186429");
                    } else if ($(this).attr("status") == 0) {
                        $(this).attr("style", "fill:#ebebeb");
                    } else {
                        $(this).attr("style", "fill:#28a745");
                    }
                });
            }

            function setSceneHeight(parentElement, maxRows) {
                if (thisIsMobile) {
                    let svgHeight = $(".scene-wrapper .seats svg", parentElement).outerHeight();
                    $(".scene-wrapper .seats").css("height", 15 + svgHeight);
                    let sceneWrapperHeight = svgHeight + 15 + $(".scene-wrapper-order .area-zone-name", parentElement).outerHeight() + $(".scene-wrapper .scene", parentElement).outerHeight();
                    $(".scene-wrapper").css("height", 15 + sceneWrapperHeight);
                } else {
                    $(".scene-wrapper", parentElement).css("height", 190 + (30 * maxRows));
                    $(".scene-wrapper .seats", parentElement).css("height", 60 + (30 * maxRows));
                }
            }

            function generateSeats(parentElementId, parentElement, seatCoordinates, maxSeatsPerRow = 0, maxRows = 0) {

                let svgContainer;

                if (thisIsMobile) {
                    svgContainer = d3.select(parentElementId + " .seats").append("svg").attr("width", "100%").attr("viewBox", "-10 10 " + Math.max(((maxSeatsPerRow * 30) + 10), 0) + " " + Math.max(((maxRows * 30) + 30), 0)).attr("preserveAspectRatio", "xMidYMid meet").attr("class", "generated-seats");
                } else {
                    svgContainer = d3.select(parentElementId + " .seats").append("svg").attr("width", Math.max(((maxSeatsPerRow * 30) - 10), 0) + "px").attr("height", "100%").attr("class", "generated-seats");
                }

                let circles = svgContainer.selectAll("circle").data(seatCoordinates).enter().append("circle");

                let circleAttributes = circles.attr("cx", function (d) {
                    return d.coordinate_x;
                }).attr("cy", function (d) {
                    return d.coordinate_y;
                }).attr("r", function (d) {
                    return d.radius;
                }).style("fill", function (d) {
                    return d.color;
                }).attr("id", function (d) {
                    return d.id;
                }).attr("rating", function (d) {
                    return d.rating;
                }).attr("status", function (d) {
                    return d.status;
                }).attr("reserved", function (d) {
                    return d.reserved;
                }).attr("row_info", function (d) {
                    return d.row_info;
                }).attr("seat_info", function (d) {
                    return d.seat_info;
                }).attr("reserved_by_others", function (d) {
                    return d.reserved_by_others;
                }).attr("class", function (d) {
                    return d.class;
                }).append("title").text(function (d) {
                    let titleElement = document.createElement('title');
                    titleElement.text = d.row_and_seat_text;
                    return d.row_and_seat_text;
                });
                setSceneHeight(parentElement, maxRows);
            }
        }
    });